const envs = {
  production: {
    API_HOST: "https://api.wisdana.com/api",
    APP_URL: "https://app.wisdana.com",
    ASSOCIATION_URL: "https://association.wisdana.com",
    CONTACT_EMAIL: "alexandre@wisdana.com",
    PUBLIC_URL: "https://equipe.wisdana.com",
    ROOT_DOMAIN: "wisdana.com",
  },

  development: {
    API_HOST: "https://api.sharingsharing.com/api",
    APP_URL: "https://app.sharingsharing.com",
    ASSOCIATION_URL: "https://association.sharingsharing.com",
    CONTACT_EMAIL: "alexandre@sharingsharing.com",
    PUBLIC_URL: "https://equipe.sharingsharing.com",
    ROOT_DOMAIN: "sharingsharing.com",
  },
};

export default envs[process.env.REACT_APP_ENV || "development"];
