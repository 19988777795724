import axios from 'axios';

import {
  GET_COLLECTES,
} from './types';

import API_ROUTES from '../../utils/api_routes';

const COLLECTES_URL = API_ROUTES.collectes;

export function getCollecteByName(name) {
  return function (dispatch) {
    dispatch({ type: GET_COLLECTES, payload: null });
    axios.get(`${COLLECTES_URL}?name=${name}`, {
      headers: { Authorization: `JWT ${localStorage.getItem('wisdana-equipe-token')}` }
    })
    .then(response => {
      dispatch({ type: GET_COLLECTES, payload: response.data });
    })
    .catch(error => {
      console.log(error.response);
    });
  }
}

export function getCollectes() {
  return function(dispatch) {
    dispatch({ type: GET_COLLECTES, payload: null });
    axios.get(`${COLLECTES_URL}`, {
      headers: { Authorization: `JWT ${localStorage.getItem('wisdana-equipe-token')}` }
    })
    .then(response => {
      dispatch({ type: GET_COLLECTES, payload: response.data });
    })
    .catch(error => {
      console.log(error.response);
      // console.log(error.response.data.error);
    })
  }
}

export function refresh() {
  return function(dispatch) {
    dispatch({ type: GET_COLLECTES, payload: null });
  }
}
