import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Redirect } from "react-router-dom";

import { Drawer } from "antd";

import { trackWindowScroll } from "react-lazy-load-image-component";

import {
  getCategories,
  getProduits,
  getProductsByName,
  getProductsIndisponible,
  postProduit,
  refresh,
} from "./actions";

import "./produits.scss";
import "../../components/produits/style.scss";

import Products from "../../components/produits/produits";
import AddProduit from "../../components/produits/addProduit";

class ProduitsContainer extends Component {
  static propTypes = {
    categories: PropTypes.oneOfType([
      PropTypes.oneOf([null]).isRequired,
      PropTypes.array.isRequired,
    ]),
    produits: PropTypes.oneOfType([
      PropTypes.oneOf([null]).isRequired,
      PropTypes.array.isRequired,
    ]),
  };

  static defaultProps = {
    categories: null,
    produits: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      drawer: false,
    };
  }

  componentDidMount() {
    this.props.getCategories();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.nouveau_produit === null &&
      this.props.nouveau_produit !== null
    ) {
      this.setState({ drawer: false });
    }
  }

  componentWillUnmount() {
    this.props.refresh();
  }

  toggleDrawer = () => {
    this.setState({ drawer: !this.state.drawer });
  };

  render() {
    const { categories, produits, nouveau_produit, scrollPosition } =
      this.props;
    const { drawer } = this.state;

    if (nouveau_produit !== null) {
      return <Redirect to={`/equipe/produit/${nouveau_produit.id}`} />;
    }

    return (
      <div className="produits-container">
        <div className="feed-buttons">
          <div
            className="feed alternate"
            onClick={() => this.props.getProductsIndisponible()}
          >
            Produit indisponible
          </div>
          <div className="feed" onClick={() => this.toggleDrawer()}>
            Ajouter un produit
          </div>
        </div>
        <h1 style={{ fontSize: "2em", color: "#2b8bbc", textAlign: "center" }}>
          Notre catalogue produits
        </h1>
        <Products
          products={produits}
          categories={categories}
          getProducts={(data) => this.props.getProduits(data)}
          getProductsByName={(data) => this.props.getProductsByName(data)}
          scrollPosition={scrollPosition}
        />
        <Drawer
          title="Ajouter un produit"
          placement="right"
          closable={true}
          onClose={() => this.toggleDrawer()}
          visible={drawer}
          className="produit-drawer"
          headerStyle={{ textAlign: "center" }}
          destroyOnClose={true}
        >
          {categories && (
            <AddProduit
              categories={categories}
              postProduit={(data) => this.props.postProduit(data)}
            />
          )}
        </Drawer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    categories: state.produits.categories,
    produits: state.produits.produits,
    nouveau_produit: state.produits.nouveau_produit,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getProduits,
      getCategories,
      getProductsByName,
      getProductsIndisponible,
      postProduit,
      refresh,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(trackWindowScroll(ProduitsContainer));
