import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Draggable } from 'react-beautiful-dnd';

export default class TrackingItemDrag extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    handleQuantityItem: PropTypes.func.isRequired,
  }

  decrement = (e, data) => {
    e.preventDefault();
    const new_quantity = data.quantity - 1
    this.props.handleQuantityItem(data.id, new_quantity);
  }

  render() {
    const { data, index, quantityChange } = this.props;

    return (
      <Draggable
        draggableId={`item-${data.id}`}
        index={index}
      >
      {(provided) => (
        <div
          ref={provided.innerRef}
          className="tracking-item"
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <p style={{ textAlign: 'center' }}>{data.article.nom}</p>
          {quantityChange && <p>Quantité: <span style={{ color: '#2b8bbc', fontWeight: 600 }}>{data.quantity}</span></p>}
          {!quantityChange && (
            <div className="number-input">
              <button onClick={(e) => this.decrement(e, data)}></button>
              <span style={{ color: '#2b8bbc', fontWeight: 600}}>{data.quantity}</span>
            </div>
          )}
        </div>
      )}
      </Draggable>
    );
  }
}
