import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import { trackWindowScroll } from 'react-lazy-load-image-component';

import {
  getAssociations,
  getAssociationByName,
  refresh,
} from './actions';

import "./associations.scss";
import "../../components/associations/style.scss";

import Associations from '../../components/associations/associations';

class AssociationsContainer extends Component {
  static propTypes = {
    associations: PropTypes.oneOfType([
      PropTypes.oneOf([null]).isRequired,
      PropTypes.array.isRequired,
    ]),
  }

  static defaultProps = {
    associations: null,
  }

  componentDidMount() {
    this.props.getAssociations();
  }

  componentWillUnmount() {
    this.props.refresh();
  }

  render() {
    const { associations, scrollPosition } = this.props;

    if (associations === null) {
      return null;
    }

    return (
      <div className="associations-container">
        <h1 style={{ fontSize: '2em', color: '#2b8bbc', textAlign: 'center' }}>Nos associations</h1>
        <Associations
          associations={associations}
          getAssociationByName={(data) => this.props.getAssociationByName(data)}
          scrollPosition={scrollPosition}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    associations: state.associations.associations,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getAssociations,
    getAssociationByName,
    refresh,
  },dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(trackWindowScroll(AssociationsContainer));
