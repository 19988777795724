import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { Drawer } from "antd";

import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";

import Logo from "../../resources/img/logo_white.svg";
import HeaderTopBG from "../../resources/img/header_top_bg.png";

import "./style.scss";

export default class Header extends Component {
  static propTypes = {
    user: PropTypes.shape({
      id: PropTypes.number.isRequired,
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    user: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      active: null,
    };
  }

  componentDidMount() {
    const { location } = this.props;
    switch (location) {
      case "/equipe/associations":
        this.setState({ active: "Associations" });
        break;
      case "/equipe/dashboard":
        this.setState({ active: "Dashboard" });
        break;
      case "/equipe/produits":
        this.setState({ active: "Nos produits" });
        break;
      case "/equipe/livraisons":
        this.setState({ active: "Livraisons" });
        break;
      case "/equipe/suggestions":
        this.setState({ active: "Suggestions de produits" });
        break;
      case "/equipe/collectes":
        this.setState({ active: "Collectes" });
        break;
      case "/equipe/applications/maintenance":
        this.setState({ active: "Maintenance" });
        break;
      default:
        this.setState({ active: "Dashboard" });
        break;
    }
  }

  onClose = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };

  toggleCollapsed = (active) => {
    if (active !== null) {
      this.setState({
        collapsed: !this.state.collapsed,
        active,
      });
    }
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  renderMobileMenu = (array) => {
    const { active } = this.state;
    return array.map((x, index) => {
      return (
        <Link
          key={index}
          onClick={() => this.toggleCollapsed(x.value)}
          to={x.link}
          className={active === x.value ? "menu-item active" : "menu-item"}
        >
          {x.value}
        </Link>
      );
    });
  };

  render() {
    const { children, user } = this.props;
    const { collapsed } = this.state;

    const mobile_array = [
      {
        link: "/equipe/associations",
        value: "Associations",
      },
      {
        link: "/equipe/dashboard",
        value: "Dashboard",
      },
      {
        link: "/equipe/produits",
        value: "Nos produits",
      },
      {
        link: "/equipe/livraisons",
        value: "Livraisons",
      },
      {
        link: "/equipe/suggestions",
        value: "Suggestions de produits",
      },
      {
        link: "/equipe/collectes",
        value: "Collectes",
      },
      {
        link: "/equipe/applications/maintenance",
        value: "Maintenance",
      },
      {
        link: "/equipe/logout",
        value: "Logout",
      },
    ];

    return (
      <div>
        <div
          className="header"
          style={{
            backgroundImage: `url(${HeaderTopBG})`,
          }}
        >
          <Drawer
            title={
              <div className="mobile-menu">
                <div onClick={() => this.toggleCollapsed(null)}>
                  <MenuFoldOutlined style={{ fontSize: 30 }} />
                </div>
              </div>
            }
            placement="left"
            closable={true}
            onClose={() => this.onClose()}
            visible={collapsed}
            className="menu-drawer"
          >
            <div className="menu-mobile-container">
              {this.renderMobileMenu(mobile_array)}
            </div>
          </Drawer>
          <div
            className="item mobile"
            onClick={() => this.toggleCollapsed(null)}
          >
            <MenuUnfoldOutlined style={{ fontSize: 30 }} />
          </div>
          <div className="item">
            <div className="first">
              <img src={Logo} alt="Wisdana" />
            </div>
          </div>
          <div className="item">
            <div className="third">
              <Link to={"/association/informations"}>
                {user?.first_name} {user?.last_name.slice(0, 1)}
              </Link>
            </div>
          </div>
        </div>
        {children}
      </div>
    );
  }
}
