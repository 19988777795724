import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CollecteView from './collecte_view';

export default class CollecteViewProduct extends Component {
  static propTypes = {
    collecte: PropTypes.object.isRequired,
    items: PropTypes.array.isRequired,
    scrollPosition: PropTypes.oneOfType([
      PropTypes.oneOf([null]).isRequired,
      PropTypes.object.isRequired,
    ]),
  }

  state = {
    menu: 0,
  }

  handleMenu = (menu) => {
    this.setState({ menu });
  }

  sort = (arr, arr2, menu) => {
    switch(menu) {
      case 0:
        return arr.filter(x => x.quantity_available !== 0);
      case 1:
        const livraison0 = arr2.filter(x => x.livraison === null);
        const delivered_livraison0 = livraison0.reduce((acc, val) => {
          if (acc[val.article.id]) {
            acc[val.article.id].quantity += val.quantity;
          } else {
            acc[val.article.id] = val;
          }
          return acc;
        }, {});
        return Object.values(delivered_livraison0);
      case 2:
        const livraison = arr2.filter(x => x.livraison !== null).filter(x => x.livraison.delivered === false);
        const delivered_livraison = livraison.reduce((acc, val) => {
          if (acc[val.article.id]) {
            acc[val.article.id].quantity += val.quantity;
          } else {
            acc[val.article.id] = val;
          }
          return acc;
        }, {});
        return Object.values(delivered_livraison);
      case 3:
        const livraison2 = arr2.filter(x => x.livraison !== null).filter(x => x.livraison.delivered === true);
        const delivered_livraison2 = livraison2.reduce((acc, val) => {
          if (acc[val.article.id]) {
            acc[val.article.id].quantity += val.quantity;
          } else {
            acc[val.article.id] = val;
          }
          return acc;
        }, {});
        return Object.values(delivered_livraison2);
      case 4:
        return arr;
      default:
        return arr;
    }
  }

  renderMenu = (selected) => {
    const arr = [{'name': 'Tous les produits', 'key': 4 }, { 'name': 'Disponible', 'key': 0 }, { 'name': 'Commandés', 'key': 1 }, { 'name': 'En livraison', 'key': 2 }, { 'name': 'Livrée', 'key': 3 }];
    return arr.map(x => {
      return (
        <p
          key={x.key}
          className={x.key === selected ? "collecte-filtre active":"collecte-filtre"}
          onClick={() => this.handleMenu(x.key)}
        >
          {x.name}
        </p>
      );
    });
  }

  render () {
    const { menu } = this.state;
    const { collecte, items, scrollPosition } = this.props;

    return [
      <div key={1} className="collecte-filtre-block">
        {this.renderMenu(menu)}
      </div>,
      <CollecteView
        key={0}
        items={items}
        order_items={this.sort(collecte.items, items, menu)}
        menu={menu}
        scrollPosition={scrollPosition}
      />
    ];
  }
}
