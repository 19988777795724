import { GET_APPLICATIONS, TOGGLE_MAINTENANCE } from "./types";

const initialState = {
  applications: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_APPLICATIONS:
      return { ...state, applications: action.payload };

    case TOGGLE_MAINTENANCE:
      const applications = state.applications.filter(
        (app) => app.url !== action.payload.url
      );
      applications.push(action.payload);
      return {
        ...state,
        applications: applications.sort((a, b) => a.id > b.id),
      };

    default:
      return { ...state };
  }
}
