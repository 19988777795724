import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Input } from 'antd';

export default class ProduitPriceCheck extends Component {
  static propTypes = {
    price_check: PropTypes.object.isRequired,
    patchProduitPriceCheck: PropTypes.func.isRequired,
  }

  static defaultProps = {
    price_check: {},
    patchProduitPriceCheck: () => {},
  }

  constructor(props) {
    super(props);
    this.state = {
      id: null,
      price: null,
      site_url: null,
      vendeur: null,
      article: null,
      ref_site: null,
    }
  }

  componentDidMount() {
    const { price_check } = this.props;
    if (price_check !== null) {
      this.setState({
        id: price_check.id,
        price: price_check.price,
        site_url: price_check.site_url,
        vendeur: price_check.vendeur,
        article: price_check.article,
        ref_site: price_check.ref_site,
      });
    }
  }

  changePrice = (price) => {
    this.setState({ price });
  }

  changeSiteUrl = (site_url) => {
    this.setState({ site_url });
  }

  changeVendeur = (vendeur) => {
    this.setState({ vendeur });
  }

  changeRefSite = (ref_site) => {
    this.setState({ ref_site });
  }

  handleSubmit = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    this.props.patchProduitPriceCheck({ ...this.state });
  }

  render() {
    return (
      <form className="produit-view-block" onSubmit={e => this.handleSubmit(e)}>
        <div className="produit-view-item">
          <div className="div-input">
            <label>Prix du vendeur</label>
            <Input
              placeholder=""
              name="price"
              value={this.state.price}
              onChange={(e) => this.changePrice(e.target.value)}
            />
          </div>
          <div className="div-input">
            <label>URL du vendeur</label>
            <Input
              placeholder=""
              name="site_url"
              value={this.state.site_url}
              onChange={(e) => this.changeSiteUrl(e.target.value)}
            />
          </div>
        </div>
        <div className="produit-view-item">
          <div className="div-input">
            <label>Ref du site</label>
            <Input
              placeholder=""
              name="ref_site"
              value={this.state.ref_site}
              onChange={(e) => this.changeRefSite(e.target.value)}
            />
          </div>
          <div className="div-input">
            <label>Vendeur</label>
            <Input
              placeholder=""
              name="vendeur"
              value={this.state.vendeur}
              onChange={(e) => this.changeVendeur(e.target.value)}
            />
          </div>
        </div>
        <div className="produit-view-item">
          <div className="submit">
            <button disabled={this.state.price === null || this.state.site_url === null || this.state.vendeur === null} type="submit">
              Enregistrer
            </button>
          </div>
          <div className="submit" onClick={() => this.props.deleteProduitPriceCheck({ price_check_id: this.state.id })}>
            <div className="delete">
              Effacer
            </div>
          </div>
        </div>
      </form>
    );
  }
}
