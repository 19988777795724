import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Drawer, Popconfirm } from 'antd';
import { CloseOutlined, EditOutlined } from '@ant-design/icons';

import moment from 'moment';

import NoteForm from './note_form';

export default class AssociationNotes extends Component {
  static propTypes = {
    notes: PropTypes.array.isRequired,
    postNote: PropTypes.func.isRequired,
    patchNote: PropTypes.func.isRequired,
    deleteNote: PropTypes.func.isRequired,
  }

  state = {
    note_text: null,
    note_open: false,
    in_update: false,
    note_id: null,
  }

  handleNoteText = (note_text) => {
    this.setState({ note_text });
  }

  handleUpdateOrCreate = () => {
    if (this.state.in_update) {
      if (this.state.note_id !== null) {
        this.props.patchNote(this.state.note_id, { text: this.state.note_text });
        this.setState({ in_update: false, note_text: null, note_open: false, note_id: null });
      }
    } else {
      this.props.postNote({ text: this.state.note_text });
      this.setState({ in_update: false, note_text: null, note_open: false, note_id: null });
    }
  }

  toggleChange = (id, text) => {
    this.setState({ note_open: true, in_update: true, note_id: id, note_text: text });
  }

  toggleOpen = (note_open) => {
    this.setState({ note_open });
  }

  toggleClose = () => {
    this.setState({ in_update: false, note_text: null, note_open: false, note_id: null });
  }

  renderNotes = (notes) => {
    return notes.map(x => {
      return (
        <div key={x.id} className="note">
          <div className="note-header">
            <p className="note-header-user">{x.user.first_name} {x.user.last_name} - {moment(x.created_at).format('ll')}</p>
            <div className="note-header-cta">
              <EditOutlined onClick={() => this.toggleChange(x.id, x.text)} />
              <Popconfirm
                title="Es-tu sur de vouloir effacer cette note ?"
                onConfirm={() => this.props.deleteNote(x.id)}
                okText="Oui"
                cancelText="Non"
              >
                <a href="#"><CloseOutlined /></a>
              </Popconfirm>
            </div>
          </div>
          <div className="note-block">
            {x.text}
          </div>
        </div>
      );
    });
  }

  render() {
    const { note_text, note_open } = this.state;
    const { notes } = this.props;

    return (
      <div className="association_view-notes">
        <div className="note-creator-cta" onClick={() => this.toggleOpen(!note_open)}>
          Créer une note
        </div>
        <Drawer
          visible={note_open}
          width={'100%'}
          onClose={() => this.toggleClose()}
          destroyOnClose={true}
        >
          <NoteForm
            text={note_text}
            handleNoteText={(data) => this.handleNoteText(data)}
            handleSubmit={() => this.handleUpdateOrCreate()}
          />
        </Drawer>

        <div className="notes-container">
          {this.renderNotes(notes)}
        </div>
      </div>
    );
  }
}
