import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Image from '../elements/image';

export default class CollecteView extends Component {
  static propTypes = {
    items: PropTypes.oneOfType([
      PropTypes.array.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
    order_items: PropTypes.oneOfType([
      PropTypes.array.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
    scrollPosition: PropTypes.oneOfType([
      PropTypes.object.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
  }

  renderCollectes = (items, order_items, menu, scrollPosition) => {
    if (menu === 4) {
      return order_items.map(x => {
        return (
          <div key={x.id} className="collecte-view-display">
            <div className="image">
              <Image
                scrollPosition={scrollPosition}
                image={{
                  alt: x.nom,
                  height: 170,
                  src: x.article.photo
                }}
              />
            </div>
            <div className="collecte-view-name active">
              <p className="titre" style={{ color: 'rgba(0,0,0,0.65)'}}>
                {x.article.nom}
              </p>
              <p className="titre" style={{ fontWeight: 600, textAlign: 'left' }}>
                {x.quantity - x.quantity_available} / {x.quantity}
              </p>
            </div>
          </div>
        );
      });
    }
    return order_items.map(x => {
      return (
        <div key={x.id} className="collecte-view-display">
          <div className="image">
            <Image
              scrollPosition={scrollPosition}
              image={{
                alt: x.nom,
                height: 170,
                src: x.article.photo
              }}
            />
          </div>
          <div className="collecte-view-name active">
            <p className="titre" style={{ color: 'rgba(0,0,0,0.65)'}}>
              {x.article.nom}
            </p>
            <p className="titre" style={{ fontWeight: 600, textAlign: 'left' }}>
              {menu !== 0 ? x.quantity:x.quantity_available}
            </p>
          </div>
        </div>
      );
    });
  }

  render() {
    const { items, order_items, menu, scrollPosition } = this.props;

    return (
      <div className="collecte-view-block">
        {items === null &&
          <div className="collecte-view-display-block">
            <div className="collecte-view-display ghost">
              <div className="image"></div>
              <p className="titre">XXX</p>
            </div>
            <div className="collecte-view-display ghost">
              <div className="image"></div>
              <p className="titre">XXX</p>
            </div>
            <div className="collecte-view-display ghost">
              <div className="image"></div>
              <p className="titre">XXX</p>
            </div>
            <div className="collecte-view-display ghost">
              <div className="image"></div>
              <p className="titre">XXX</p>
            </div>
            <div className="collecte-view-display ghost">
              <div className="image"></div>
              <p className="titre">XXX</p>
            </div>
          </div>
        }
        {items && items.length > 0 &&
          <div className="collecte-view-display-block">
            {this.renderCollectes(items, order_items, menu, scrollPosition)}
          </div>
        }
      </div>
    );
  }
}
