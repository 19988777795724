import axios from 'axios';

import {
  GET_PRODUIT,
  PATCH_PRODUIT,
  GET_PRODUIT_VENDEUR,
  PATCH_PRODUIT_VENDEUR,
  GET_PRODUIT_PRICE_CHECK,
  POST_PRODUIT_PRICE_CHECK,
  PATCH_PRODUIT_PRICE_CHECK,
  DELETE_PRODUIT_PRICE_CHECK,
} from './types';

import API_ROUTES from '../../utils/api_routes';

const PRODUCTS_URL = API_ROUTES.products;

export function getProduit(produit_id) {
  return function(dispatch) {
    axios.get(`${PRODUCTS_URL}${produit_id}/`, {
      headers: { Authorization: `JWT ${localStorage.getItem('wisdana-equipe-token')}` }
    })
    .then(response => {
      dispatch({ type: GET_PRODUIT, payload: response.data });
    })
    .catch(error => {
      console.log(error.response);
      // console.log(error.response.data.error);
    });
  }
}

export function patchProduit(produit_id, data) {
  return function(dispatch) {
    const formData = new FormData();

    for (const key in data) {
      formData.append(key, data[key]);
    }

    axios.patch(`${PRODUCTS_URL}${produit_id}/`, formData, {
      headers: { Authorization: `JWT ${localStorage.getItem('wisdana-equipe-token')}` }
    })
    .then(response => {
      dispatch({ type: PATCH_PRODUIT, payload: response.data });
    })
    .catch(error => {
      console.log(error.response);
      // console.log(error.response.data.error);
    });
  }
}

export function getProduitVendeur(produit_id) {
  return function(dispatch) {
    axios.get(`${PRODUCTS_URL}${produit_id}/vendeur/`, {
      headers: { Authorization: `JWT ${localStorage.getItem('wisdana-equipe-token')}` }
    })
    .then(response => {
      dispatch({ type: GET_PRODUIT_VENDEUR, payload: response.data });
    })
    .catch(error => {
      console.log(error.response);
      // console.log(error.response.data.error);
    });
  }
}

export function patchProduitVendeur(produit_id, data) {
  return function(dispatch) {
    axios.patch(`${PRODUCTS_URL}${produit_id}/vendeur/`, data, {
      headers: { Authorization: `JWT ${localStorage.getItem('wisdana-equipe-token')}` }
    })
    .then(response => {
      dispatch({ type: PATCH_PRODUIT_VENDEUR, payload: response.data });
    })
    .catch(error => {
      console.log(error.response);
      // console.log(error.response.data.error);
    });
  }
}

export function getProduitPriceCheck(produit_id) {
  return function(dispatch) {
    axios.get(`${PRODUCTS_URL}${produit_id}/price-check/`, {
      headers: { Authorization: `JWT ${localStorage.getItem('wisdana-equipe-token')}` }
    })
    .then(response => {
      dispatch({ type: GET_PRODUIT_PRICE_CHECK, payload: response.data });
    })
    .catch(error => {
      console.log(error.response);
      // console.log(error.response.data.error);
    });
  }
}

export function patchProduitPriceCheck(produit_id, data) {
  return function(dispatch) {
    axios.patch(`${PRODUCTS_URL}${produit_id}/price-check/`, data, {
      headers: { Authorization: `JWT ${localStorage.getItem('wisdana-equipe-token')}` }
    })
    .then(response => {
      dispatch({ type: PATCH_PRODUIT_PRICE_CHECK, payload: response.data });
    })
    .catch(error => {
      console.log(error.response);
      // console.log(error.response.data.error);
    });
  }
}

export function postProduitPriceCheck(produit_id) {
  return function(dispatch) {
    axios.post(`${PRODUCTS_URL}${produit_id}/price-check/`, {}, {
      headers: { Authorization: `JWT ${localStorage.getItem('wisdana-equipe-token')}` }
    })
    .then(response => {
      dispatch({ type: POST_PRODUIT_PRICE_CHECK, payload: response.data });
    })
    .catch(error => {
      console.log(error.response);
      // console.log(error.response.data.error);
    });
  }
}

export function deleteProduitPriceCheck(produit_id, data) {
  return function(dispatch) {
    axios.delete(`${PRODUCTS_URL}${produit_id}/price-check/`, {
      params: data,
      headers: { Authorization: `JWT ${localStorage.getItem('wisdana-equipe-token')}` }
    })
    .then(response => {
      dispatch({ type: DELETE_PRODUIT_PRICE_CHECK, payload: response.data });
    })
    .catch(error => {
      console.log(error.response);
      // console.log(error.response.data.error);
    });
  }
}

export function refresh() {
  return function(dispatch) {
    dispatch({ type: GET_PRODUIT, payload: null });
    dispatch({ type: GET_PRODUIT_VENDEUR, payload: null });
    dispatch({ type: GET_PRODUIT_PRICE_CHECK, payload: null });
  }
}
