import React, { Component } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

export default class Information extends Component {
  static propTypes = {
    association: PropTypes.object.isRequired,
  }

  static defaultProps = {
    association: {},
  }

  render() {
    const { association } = this.props;
    return (
      <div className="association_view-information-block">
        <div className="association_view-information-item">
          <img src={association.photo} alt={association.nom} />
        </div>
        <div className="association_view-information-item" style={{ alignSelf: 'flex-start' }}>
          <h3 className="titre">Date de creation</h3>
          <div className="field">
            {moment(association.created_at).format('ll')}
          </div>
          <h3 className="titre">Email de l'association</h3>
          <div className="field">
            {association.email} {association.email_verified ? <span>(Email confirmé)</span>:<span style={{ color: 'red' }}>(Email non-confirmé)</span>}
          </div>
          <h3 className="titre">Adresse de l'association</h3>
          <div className="field">
            {association.adresse}
          </div>
          <div className="field">
            {association.code_postal}
            {association.ville}
          </div>
          <h3 className="titre">Description de l'association</h3>
          <div className="field">
            {association.description}
          </div>
          <h3 className="titre">Contact de l'association</h3>
          <div className="field">
            <p>{association.telephone}</p>
            <p>{association.site_web}</p>
          </div>
          <h3 className="titre">Reconnaissance</h3>
          <div className="field">
            {association.reconnaissance}
          </div>
          <h3 className="titre">Domaine d'activité</h3>
          <div className="field">
            {association.domaine_activite}
          </div>
          {association.defiscalisable &&
            <h3 className="titre">Pourcentage de défiscalisation</h3>
          }
          {association.defiscalisable &&
            <div className="field">
              {association.defiscalisation_percent}
            </div>
          }
        </div>
      </div>
    );
  }
}
