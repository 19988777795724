import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Input } from 'antd';
import { Link } from 'react-router-dom';

import Image from '../elements/image';

const { Search } = Input;

export default class Associations extends Component {
  static propTypes = {
    associations: PropTypes.oneOfType([
      PropTypes.array.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
    getAssociationByName: PropTypes.func.isRequired,
    scrollPosition: PropTypes.oneOfType([
      PropTypes.object.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
  }

  static defaultProps = {
    associations: null,
    getAssociationByName: () => {},
    scrollPosition: null,
  }

  renderAssociations = (associations, scrollPosition) => {
    return associations.map(x => {
      return (
        <div key={x.id} className={x.email_verified ? "association-display":"association-display not-confirmed"}>
          <Link to={`/equipe/association/${x.id}`}>
            <div className="image">
              <Image
                scrollPosition={scrollPosition}
                image={{
                  alt: x.nom,
                  height: 170,
                  src: x.photo
                }}
              />
            </div>
            <div className="association-name">
              <div className="association-name-logo" style={{ backgroundImage: `url('${x.logo}')` }}></div>
              <p className="titre" style={{ color: 'rgba(0,0,0,0.65)'}}>
                {x.nom}
              </p>
            </div>
          </Link>
        </div>
      );
    });
  }

  handleAssociationByName = (value) => {
    if (value !== "") {
      this.props.getAssociationByName(value);
    }
  }

  renderSearchForm = () => {
    return (
      <div className="search-container">
        <Search
          placeholder="Rechercher une association par nom"
          onSearch={value => this.handleAssociationByName(value)}
          className="search"
        />
      </div>
    );
  }

  render() {
    const { associations, scrollPosition } = this.props;

    return (
      <div className="associations-block">
        {this.renderSearchForm()}
        {associations === null &&
          <div className="associations-display-block">
            <div className="association-display ghost">
              <div className="image"></div>
              <p className="titre">XXX</p>
            </div>
            <div className="association-display ghost">
              <div className="image"></div>
              <p className="titre">XXX</p>
            </div>
            <div className="association-display ghost">
              <div className="image"></div>
              <p className="titre">XXX</p>
            </div>
            <div className="association-display ghost">
              <div className="image"></div>
              <p className="titre">XXX</p>
            </div>
            <div className="association-display ghost">
              <div className="image"></div>
              <p className="titre">XXX</p>
            </div>
          </div>
        }
        {associations && associations.length > 0 &&
          <div className="associations-display-block">
            {this.renderAssociations(associations, scrollPosition)}
          </div>
        }
      </div>
    );
  }
}
