import axios from 'axios';

import {
  GET_ASSOCIATION,
  GET_ASSOCIATION_COLLECTES,
  PATCH_ASSOCIATION,
  GET_NOTES,
  POST_NOTE,
  PATCH_NOTE,
  DELETE_NOTE,
} from './types';

import API_ROUTES from '../../utils/api_routes';

const ASSOCIATIONS_URL = API_ROUTES.associations;

export function getNotes(id) {
  return function(dispatch) {
    axios.get(`${ASSOCIATIONS_URL}${id}/notes/`, {
      headers: { Authorization: `JWT ${localStorage.getItem('wisdana-equipe-token')}` }
    })
    .then(response => {
      dispatch({ type: GET_NOTES, payload: response.data });
    })
    .catch(error => {
      console.log(error.response);
    })
  }
}

export function postNote(id, data) {
  return function(dispatch) {
    axios.post(`${ASSOCIATIONS_URL}${id}/notes/`, data, {
      headers: { Authorization: `JWT ${localStorage.getItem('wisdana-equipe-token')}` }
    })
    .then(response => {
      dispatch({ type: POST_NOTE, payload: response.data });
    })
    .catch(error => {
      console.log(error.response);
    })
  }
}

export function patchNote(id, note_id, data) {
  return function(dispatch) {
    axios.patch(`${ASSOCIATIONS_URL}${id}/notes/${note_id}/`, data, {
      headers: { Authorization: `JWT ${localStorage.getItem('wisdana-equipe-token')}` }
    })
    .then(response => {
      dispatch({ type: PATCH_NOTE, payload: response.data });
    })
    .catch(error => {
      console.log(error.response);
    })
  }
}

export function deleteNote(id, note_id) {
  return function(dispatch) {
    axios.delete(`${ASSOCIATIONS_URL}${id}/notes/${note_id}/`, {
      headers: { Authorization: `JWT ${localStorage.getItem('wisdana-equipe-token')}` }
    })
    .then(response => {
      dispatch({ type: DELETE_NOTE, payload: note_id });
    })
    .catch(error => {
      console.log(error.response);
    })
  }
}

export function getAssociation(id) {
  return function(dispatch) {
    axios.get(`${ASSOCIATIONS_URL}${id}/`, {
      headers: { Authorization: `JWT ${localStorage.getItem('wisdana-equipe-token')}` }
    })
    .then(response => {
      dispatch({ type: GET_ASSOCIATION, payload: response.data });
    })
    .catch(error => {
      console.log(error.response);
      // console.log(error.response.data.error);
    })
  }
}

export function getAssociationCollectes(id) {
  return function(dispatch) {
    axios.get(`${ASSOCIATIONS_URL}${id}/collectes/`, {
      headers: { Authorization: `JWT ${localStorage.getItem('wisdana-equipe-token')}`}
    })
    .then(response => {
      dispatch({ type: GET_ASSOCIATION_COLLECTES, payload: response.data });
    })
    .catch(error => {
      console.log(error.response);
    })
  }
}

export function patchAssociation(id, data) {
  return function(dispatch) {
    axios.patch(`${ASSOCIATIONS_URL}${id}/`, data, {
      headers: { Authorization: `JWT ${localStorage.getItem('wisdana-equipe-token')}` }
    })
    .then(response => {
      dispatch({ type: PATCH_ASSOCIATION, payload: response.data });
    })
    .catch(error => {
      console.log(error.response);
      // console.log(error.response.data.error);
    });
  }
}

export function refresh() {
  return function(dispatch) {
    dispatch({ type: GET_ASSOCIATION, payload: null });
    dispatch({ type: GET_ASSOCIATION_COLLECTES, payload: null });
  }
}
