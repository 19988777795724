import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import { signoutUser } from './actions';


class LogoutContainer extends Component {
  static propTypes = {
    authenticated: PropTypes.oneOfType([
      PropTypes.bool.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
  };

  static defaultProps = {
    authenticated: null,
  };

  componentDidMount() {
    this.props.signoutUser();
  }

  render () {
    const { authenticated } = this.props;

    if (authenticated === false) {
      return <Redirect push={true} to="/app/feed" />;
    }

    return (
      <div> Logout </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    authenticated: state.auth.authenticated,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    signoutUser,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(LogoutContainer);
