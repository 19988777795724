import {
	GET_LIVRAISON,
	GET_LIVRAISON_ADRESSE,
	PATCH_LIVRAISON,
	PATCH_ORDER_LINE_ITEM,
	GET_LIVRAISON_COMMANDE,
	PATCH_LIVRAISON_COMMANDE,
  PATCH_LIVRAISON_BON,
	POST_LIVRAISON_BON,
	GET_LIVRAISON_BON,
	PATCH_LIVRAISON_ITEM,
	PATCH_LIVRAISON_ITEM_UPDATING,
} from './types';

const initialState = {
  livraison: null,
	commandes: null,
	bon: null,
	livraison_item: null,
	adresse: null,
	updating: false,
}

function updateLivraisonCommande(state, livraison_commande) {
	const { livraison } = state;
	if (livraison === null) {
		return { ...state, livraison: null };
	} else {
		const new_livraison = livraison;
		const new_commandes = new_livraison.commandes.map(x => {
			if (x.id === livraison_commande.id) {
				return livraison_commande;
			}
			return x;
		});
		new_livraison.commandes = new_commandes;
		return { ...state, livraison: new_livraison };
	}
}

function updateLivraisonBon(state, livraison_bon) {
	const { livraison } = state;
	if (livraison === null) {
		return { ...state, livraison: null };
	} else {
		const new_livraison = livraison;
		const new_commandes = new_livraison.commandes.map(x => {
			const new_bon_de_commandes = x.bonDeCommandes.map(y => {
				if (y.id === livraison_bon.id) {
					return livraison_bon;
				}
				return y;
			});
			x.bonDeCommandes = new_bon_de_commandes;
			return x;
		});
		new_livraison.commandes = new_commandes;
		return { ...state, livraison: new_livraison };
	}
}

function postLivraisonBon(state, livraison_bon) {
	const { livraison } = state;
	if (livraison === null) {
		return { ...state, livraison: null };
	} else {
		const new_livraison = livraison;
		new_livraison.commandes = new_livraison.commandes.map(x => {
			if (x.id === livraison_bon.livraison_commande) {
				x.bonDeCommandes = [...x.bonDeCommandes, livraison_bon];
				return x;
			}
			return x;
		});
		return { ...state, livraison: new_livraison, bon: livraison_bon };
	}
}

export default function (state = initialState, action) {
	switch(action.type) {
		case GET_LIVRAISON:
      return { ...state, livraison: action.payload };
		case GET_LIVRAISON_ADRESSE:
			return { ...state, adresse: action.payload };
		case PATCH_LIVRAISON:
			return { ...state, livraison: action.payload };
		case PATCH_ORDER_LINE_ITEM:
			return { ...state, commandes: action.payload };
		case GET_LIVRAISON_COMMANDE:
			return { ...state, commandes: action.payload };
		case PATCH_LIVRAISON_COMMANDE:
			return updateLivraisonCommande(state, action.payload);
		case GET_LIVRAISON_BON:
			return { ...state, bon: action.payload };
		case PATCH_LIVRAISON_BON:
			return updateLivraisonBon(state, action.payload);
		case POST_LIVRAISON_BON:
			return postLivraisonBon(state, action.payload);
		case PATCH_LIVRAISON_ITEM:
			return { ...state, livraison_item: action.payload, updating: true };
		case PATCH_LIVRAISON_ITEM_UPDATING:
			return { ...state, updating: false };
		default:
			return { ...state };
	}
}
