import env from "../config/env";
const { API_HOST } = env;

const API_ROUTES = {
  login: `${API_HOST}/equipe/login/`,
  token_refresh: `${API_HOST}/equipe/token-refresh/`,
  token_verify: `${API_HOST}/equipe/token-verify/`,
  user: `${API_HOST}/equipe/user/`,
  products: `${API_HOST}/equipe/products/`,
  categories: `${API_HOST}/equipe/categories/`,
  livraisons: `${API_HOST}/equipe/livraisons/`,
  associations: `${API_HOST}/equipe/associations/`,
  collectes: `${API_HOST}/equipe/collectes/`,
  orders: `${API_HOST}/equipe/orders/`,
  orders_successfull: `${API_HOST}/equipe/orders-successfull/`,
  livraison_item: `${API_HOST}/equipe/livraison-commande-item/`,
  suggestions: `${API_HOST}/equipe/suggestions/`,
  cash: `${API_HOST}/equipe/cash/`,
  applications: `${API_HOST}/equipe/applications/`,
};

export default API_ROUTES;
