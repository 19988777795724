import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Droppable } from 'react-beautiful-dnd';

import TrackingItemDrag from './trackingItemDrag';

export default class TrackingItemsDrag extends Component {
  static propTypes = {
    items: PropTypes.object.isRequired,
    handleQuantityItem: PropTypes.func.isRequired,
  }

  render() {
    const { items } = this.props;

    return (
      <Droppable key={items.id} droppableId={`${items.id}`} direction="horizontal" type="item">
      {(provided) => (
          <div
            ref={provided.innerRef}
            className="tracking-items-block"
            {...provided.droppableProps}
          >
              {items.commandeItems.map((x, index) => {
                return <TrackingItemDrag
                key={x.id}
                data={x}
                index={index}
                quantityChange={items.default}
                handleQuantityItem={(id, data) => this.props.handleQuantityItem(id, data)}
                />
              })}
              {provided.placeholder}
          </div>
        )}
      </Droppable>
    );
  }
}
