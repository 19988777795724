import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Image from './image';

import moment from 'moment';

import { trackWindowScroll } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';

class CollecteCard extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    scrollPosition: PropTypes.oneOfType([
      PropTypes.oneOf([null]).isRequired,
      PropTypes.object.isRequired,
    ]),
  }

  calculateNombreDon = (items) => {
    return items.reduce((acc, val) => {
      acc += val.quantity - val.quantity_available;
      return acc;
    }, 0);
  }

  render() {
    const { data, scrollPosition } = this.props;

    return (
      <div className="collecte-card">
        <Link to={`/equipe/collecte/${data.id}`}>
          <div className={!data.terminer ? "collecte-card-header":"collecte-card-header done"}>
            {data.nom}
          </div>
          <div className="collecte-card-image">
            <Image
              scrollPosition={scrollPosition}
              image={{
                alt: data.nom,
                height: 200,
                src: data.photo
              }}
            />
          </div>
          <div className="collecte-card-block">
            <div className="collecte-card-date">
              {moment(data.date_debut).format('ll')} - {moment(data.date_fin).format('ll')}
            </div>
            <div className="collecte-card-stats">
              <div className="collecte-card-stat">
                Nombre de dons: {this.calculateNombreDon(data.items)}
              </div>
              <div className="collecte-card-stat">
                Total des dons: {data.special.price.toFixed(2)}€
              </div>
              <div className="collecte-card-stat">
                Total des depenses: {data.special.depense.toFixed(2)}€
              </div>
            </div>
          </div>
        </Link>
      </div>
    );
  }
}

export default trackWindowScroll(CollecteCard);
