import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Verification extends Component {
  static propTypes = {
    association: PropTypes.object.isRequired,
  }

  static defaultProps = {
    association: {},
  }

  render() {
    const { documents } = this.props;
    return (
      <div className="association_view-information-block">
        <div className="association_view-information-item">
          <h3 className="titre">Carte d'identité du responsable</h3>
          <div className="field">
            {!documents.responsable_id &&
              "Aucun"
            }
            {documents.responsable_id &&
              <a href={documents.responsable_id} target="_blank" rel="noopener noreferrer">
                Identité du responsable
              </a>
            }
          </div>
          <h3 className="titre">Parution au journal officiel</h3>
          <div className="field">
            {!documents.parution_journal_officiel &&
              "Aucun"
            }
            {documents.parution_journal_officiel &&
              <a href={documents.parution_journal_officiel} target="_blank" rel="noopener noreferrer">
                Parution au journal officiel
              </a>
            }
          </div>
          <h3 className="titre">Statuts Signés</h3>
          <div className="field">
            {!documents.statuts_signe &&
              "Aucun"
            }
            {documents.statuts_signe &&
              <a href={documents.statuts_signe} target="_blank" rel="noopener noreferrer">
                Statuts Signés
              </a>
            }
          </div>
          <h3 className="titre">Liste signée des membres</h3>
          <div className="field">
            {!documents.liste_signee_membre &&
              "Aucun"
            }
            {documents.liste_signee_membre &&
              <a href={documents.liste_signee_membre} target="_blank" rel="noopener noreferrer">
                Liste signée des membres
              </a>
            }
          </div>
          <h3 className="titre">Signature</h3>
          <div className="field">
            {!documents.signature &&
              "Aucune"
            }
            {documents.signature &&
              <img src={documents.signature} alt={"Signature"} />
            }
          </div>
        </div>
        <div className="association_view-information-item" style={{ alignSelf: 'flex-start' }}>
          <h3 className="titre">Nom et prénom du responsable</h3>
          <div className="field">
            {documents.last_name} {documents.first_name}
          </div>
          <h3 className="titre">Adresse du responsable</h3>
          <div className="field">
            {documents.adresse}
          </div>
          <div className="field">
            {documents.code_postal}
            {documents.ville}
          </div>
          <h3 className="titre">Raison sociale de l'association</h3>
          <div className="field">
            {documents.raison_sociale}
          </div>
          <h3 className="titre">Statut et type d'association</h3>
          <div className="field">
            {documents.statut_juridique} {documents.type_association}
          </div>
          <h3 className="titre">Numéro rna</h3>
          <div className="field">
            {documents.numero_rna}
          </div>
          <h3 className="titre">Objet de la structure</h3>
          <div className="field">
            {documents.objet_structure}
          </div>
          <h3 className="titre">Information du signataire</h3>
          <div className="field">
            {documents.identite_signataire}, {documents.fonction_signataire}
          </div>
        </div>
      </div>
    );
  }
}
