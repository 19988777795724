import axios from "axios";

import { GET_APPLICATIONS, TOGGLE_MAINTENANCE } from "./types";
import API_ROUTES from "../../utils/api_routes";

export const getApplications = () => {
  return (dispatch) => {
    axios
      .get(API_ROUTES.applications, {
        headers: {
          Authorization: `JWT ${localStorage.getItem("wisdana-equipe-token")}`,
        },
      })
      .then((response) => {
        dispatch({
          type: GET_APPLICATIONS,
          payload: response.data.sort((a, b) => a.id > b.id),
        });
      })
      .catch((error) => {
        console.error(error.response);
      });
  };
};

export const toggleMaintenance = (website_url, maintenance) => {
  return (dispatch) => {
    axios
      .post(
        API_ROUTES.applications + "maintenance/update/",
        {
          website_url,
          maintenance,
        },
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem(
              "wisdana-equipe-token"
            )}`,
          },
        }
      )
      .then((response) => {
        dispatch({ type: TOGGLE_MAINTENANCE, payload: response.data });
      })
      .catch((error) => {
        console.error(error.response);
      });
  };
};
