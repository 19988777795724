import {
	GET_ORDERS,
  GET_ASSOCIATIONS,
  GET_COLLECTES,
	GET_CASH,
} from './types';

const initialState = {
  orders: null,
  associations: null,
  collectes: null,
	cash: null,
}

export default function (state = initialState, action) {
	switch(action.type) {
		case GET_ORDERS:
      return { ...state, orders: action.payload };
    case GET_ASSOCIATIONS:
      return { ...state, associations: action.payload };
    case GET_COLLECTES:
      return { ...state, collectes: action.payload };
		case GET_CASH:
			return { ...state, cash: action.payload };
		default:
			return { ...state };
	}
}
