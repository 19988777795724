import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CollecteCard from '../elements/collecteCard';

export default class AssociationCollectes extends Component {
  static propTypes = {
    collectes: PropTypes.oneOfType([
      PropTypes.array.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
  }
  render() {
    const { collectes } = this.props;

    return (
      <div className="association_view-collectes">
        {collectes !== null && collectes.map(x => <CollecteCard key={x.id} data={x} />)}
      </div>
    )
  }
}
