export const GET_LIVRAISON = 'containers/livraison_view/get_livraison';
export const GET_LIVRAISON_ADRESSE = 'containers/livraison_view/get_livraison_adresse';
export const PATCH_LIVRAISON = 'containers/livraison_view/patch_livraison';

export const PATCH_ORDER_LINE_ITEM = 'containers/livraison_view/patch_order_line_item';

export const GET_LIVRAISON_COMMANDE = 'containers/livraison_view/get_livraison_commande';
export const PATCH_LIVRAISON_COMMANDE = 'containers/livraison_view/patch_livraison_commande';

export const GET_LIVRAISON_BON = 'containers/livraison_view/get_livraison_bon';
export const POST_LIVRAISON_BON = 'containers/livraison_view/post_livraison_bon';
export const PATCH_LIVRAISON_BON = 'containers/livraison_view/patch_livraison_bon';

export const PATCH_LIVRAISON_ITEM = 'containers/livraison_view/patch_livraison_item';
export const PATCH_LIVRAISON_ITEM_UPDATING = 'containers/livraison_view/patch_livraison_item_updating';
