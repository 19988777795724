import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Input } from 'antd';
import { Link } from 'react-router-dom';

import Image from '../elements/image';

const { Search } = Input;

export default class Collectes extends Component {
  static propTypes = {
    collectes: PropTypes.oneOfType([
      PropTypes.array.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
    getCollecteByName: PropTypes.func.isRequired,
    scrollPosition: PropTypes.oneOfType([
      PropTypes.object.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
  }

  renderCollectes = (collectes, scrollPosition) => {
    return collectes.map(x => {
      return (
        <div key={x.id} className="collecte-display">
          <Link to={`/equipe/collecte/${x.id}`}>
            <div className="collecte-name">
              <p className="titre">
                {x.nom}
              </p>
            </div>
            <div className="image">
              <Image
                scrollPosition={scrollPosition}
                image={{
                  alt: x.nom,
                  height: 170,
                  src: x.photo
                }}
              />
            </div>
            <div className="collecte-body">
              À livrer: {x.to_be_delivered}€
            </div>
          </Link>
        </div>
      );
    });
  }

  handleCollecteByName = (value) => {
    if (value !== "") {
      this.props.getCollecteByName(value);
    }
  }

  renderSearchForm = () => {
    return (
      <div className="search-container">
        <Search
          placeholder="Rechercher une collecte par nom d'association"
          onSearch={value => this.handleCollecteByName(value)}
          className="search"
        />
      </div>
    );
  }

  render() {
    const { collectes, scrollPosition } = this.props;

    return (
      <div className="collectes-block">
        {this.renderSearchForm()}
        {collectes === null &&
          <div className="collectes-display-block">
            <div className="collecte-display ghost">
              <div className="image"></div>
              <p className="titre">XXX</p>
            </div>
            <div className="collecte-display ghost">
              <div className="image"></div>
              <p className="titre">XXX</p>
            </div>
            <div className="collecte-display ghost">
              <div className="image"></div>
              <p className="titre">XXX</p>
            </div>
            <div className="collecte-display ghost">
              <div className="image"></div>
              <p className="titre">XXX</p>
            </div>
            <div className="collecte-display ghost">
              <div className="image"></div>
              <p className="titre">XXX</p>
            </div>
          </div>
        }
        {collectes && collectes.length > 0 &&
          <div className="collectes-display-block">
            {this.renderCollectes(collectes, scrollPosition)}
          </div>
        }
      </div>
    );
  }
}
