import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Upload, message } from 'antd';
import { LoadingOutlined, InboxOutlined } from '@ant-design/icons';

async function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('Vous pouvez seulement utiliser des fichiers JPG/PNG !');
  }
  const isLt2M = file.size / 1024 / 1024 < 6;
  if (!isLt2M) {
    message.error("La taille de l'image ne doit pas dépasser 6 Mo !");
  }

  return isJpgOrPng && isLt2M;
}

export default class ImageUploader extends Component {
  static propTypes = {
    changePhoto: PropTypes.func.isRequired,
  }

  state = {
    loading: false,
  };

  componentDidMount() {
    if (this.props.image) {
      this.setState({ imageUrl: this.props.image });
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.image === null && this.props.image !== null) {
      this.setState({ imageUrl: this.props.image });
    }
  }

  handleImageUpload = (imageUrl, photo) => {
    this.setState({ loading: false, imageUrl });
    this.props.changePhoto(photo);
  }

  handleChange = info => {
    if (info.file.status === 'uploading') {
      this.setState({ loading: true });
      return;
    }

    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, imageUrl => {
        const image = new Image();
        image.src = imageUrl;

        image.onload = (e) => {
          this.handleImageUpload(imageUrl, info.file.originFileObj);
        }
      });
    }
  };

  render() {
    const uploadButton = (
      <div>
        <p className="ant-upload-drag-icon" style={{ fontSize: 30, color: '#2b8bbc' }}>
          {this.state.loading ? <LoadingOutlined /> : <InboxOutlined />}
        </p>
        <p className="ant-upload-text">Cliquez ici ou faites glisser un fichier</p>
      </div>
    );
    const { imageUrl } = this.state;

    return (
      <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        customRequest={dummyRequest}
        beforeUpload={beforeUpload}
        onChange={this.handleChange}
      >
        {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
      </Upload>
    );
  }
}
