import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Input } from 'antd';

export default class ProduitVendeur extends Component {
  static propTypes = {
    vendeur: PropTypes.object.isRequired,
    patchProduitVendeur: PropTypes.func.isRequired,
  }

  static defaultProps = {
    vendeur: {},
    patchProduitVendeur: () => {},
  }

  constructor(props) {
    super(props);
    this.state = {
      id: null,
      prix: null,
      url: null,
      vendeur: null,
      article: null,
    }
  }

  componentDidMount() {
    const { vendeur } = this.props;
    if (vendeur !== null) {
      this.setState({
        id: vendeur.id,
        prix: vendeur.prix,
        url: vendeur.url,
        vendeur: vendeur.vendeur,
        article: vendeur.article,
      });
    }
  }

  changePrix = (prix) => {
    this.setState({ prix });
  }

  changeUrl = (url) => {
    this.setState({ url });
  }

  changeVendeur = (vendeur) => {
    this.setState({ vendeur });
  }

  handleSubmit = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    this.props.patchProduitVendeur({ ...this.state });
  }

  render() {
    return (
      <form className="produit-view-block" onSubmit={e => this.handleSubmit(e)}>
        <div className="produit-view-vendeur-item">
          <div className="div-input">
            <label>Prix du vendeur</label>
            <Input
              placeholder=""
              name="prix"
              value={this.state.prix}
              onChange={(e) => this.changePrix(e.target.value)}
            />
          </div>
        </div>
        <div className="produit-view-vendeur-item">
          <div className="div-input">
            <label>URL du vendeur</label>
            <Input
              placeholder=""
              name="url"
              value={this.state.url}
              onChange={(e) => this.changeUrl(e.target.value)}
            />
          </div>
        </div>
        <div className="produit-view-vendeur-item">
          <div className="div-input">
            <label>Vendeur</label>
            <Input
              placeholder=""
              name="vendeur"
              value={this.state.vendeur}
              onChange={(e) => this.changeVendeur(e.target.value)}
            />
          </div>
        </div>
        <div className="submit">
          <button disabled={this.state.prix === null || this.state.url === null || this.state.vendeur === null} type="submit">
            Enregistrer
          </button>
        </div>
      </form>
    );
  }
}
