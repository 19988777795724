import {
	GET_SUGGESTIONS,
	PATCH_SUGGESTIONS,
} from './types';

const initialState = {
  suggestions: null,
}

function patchSuggestion(state, suggestion) {
	const suggestions = state.suggestions;

	if (suggestions.length === 0) {
		return { ...state, suggestions }
	}
	const new_suggestions = suggestions.map(x => {
		if (x.id === suggestion.id) {
			return suggestion;
		} else {
			return x;
		}
	});
	return { ...state, suggestions: new_suggestions };
}

export default function (state = initialState, action) {
	switch(action.type) {
		case GET_SUGGESTIONS:
			return { ...state, suggestions: action.payload };
		case PATCH_SUGGESTIONS:
			return patchSuggestion(state, action.payload);
		default:
			return { ...state };
	}
}
