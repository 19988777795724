import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import {
  getSuggestions,
  patchSuggestion,
  refresh,
} from './actions';

import "./suggestions.scss";

import Suggestions from '../../components/suggestions/suggestions';

class SuggestionsContainer extends Component {
  static propTypes = {
    suggestions: PropTypes.oneOfType([
      PropTypes.oneOf([null]).isRequired,
      PropTypes.array.isRequired,
    ]),
  }

  componentDidMount() {
    this.props.getSuggestions();
  }

  componentWillUnmount() {
    this.props.refresh();
  }

  render() {
    const { suggestions } = this.props;

    return (
      <div className="produits-container">
        <h1 style={{ fontSize: '2em', color: '#2b8bbc', textAlign: 'center' }}>Suggestions des associations</h1>
        <Suggestions
          data={suggestions}
          patchSuggestion={(id) => this.props.patchSuggestion(id)}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    suggestions: state.suggestions.suggestions,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getSuggestions,
    patchSuggestion,
    refresh,
  },dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SuggestionsContainer);
