// export * from './pages/home/reducer';
import { combineReducers } from "redux";
import AuthReducer from "../containers/auth/reducers";
import LayoutReducer from "../containers/layout/reducers";
import ProduitsReducer from "../containers/produits/reducers";
import ProduitViewReducer from "../containers/produit_view/reducers";
import LivraisonsReducer from "../containers/livraisons/reducers";
import LivraisonViewReducer from "../containers/livraison_view/reducers";
import AssociationsReducer from "../containers/associations/reducers";
import AssociationViewReducer from "../containers/association_view/reducers";
import DashboardReducer from "../containers/dashboard/reducers";
import SuggestionsReducer from "../containers/suggestions/reducers";
import CollectesReducer from "../containers/collectes/reducers";
import CollecteViewReducer from "../containers/collecte_view/reducers";
import MaintenanceReducer from "../containers/maintenance/reducers";

const rootReducer = combineReducers({
  auth: AuthReducer,
  layout: LayoutReducer,
  produits: ProduitsReducer,
  produit_view: ProduitViewReducer,
  livraisons: LivraisonsReducer,
  livraison_view: LivraisonViewReducer,
  associations: AssociationsReducer,
  association_view: AssociationViewReducer,
  dashboard: DashboardReducer,
  suggestions: SuggestionsReducer,
  collectes: CollectesReducer,
  collecte_view: CollecteViewReducer,
  applications: MaintenanceReducer,
});

export default rootReducer;
