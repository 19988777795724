import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

// Unauth Routes
import LoginContainer from '../containers/auth/authContainer';
import LogoutContainer from '../containers/auth/logoutContainer';

// Auth Routes
import Layout from '../containers/layout/layoutContainer';

export default (
	<Switch>
		<Route
			path="/equipe"
			exact
			render={() => {
          return <Redirect to="/equipe/login" />;
      }}
		/>
		<Route
			path="/"
			exact
			render={() => {
          return <Redirect to="/equipe/login" />;
      }}
		/>
    <Route path="/equipe/login" exact component={LoginContainer} />
		<Route path="/equipe/logout" exact component={LogoutContainer} />
		<Layout />
	</Switch>
);
