import {
  GET_COLLECTE,
  GET_COLLECTE_CSV,
  GET_COLLECTE_ITEM,
  GET_COLLECTE_LIVRAISONS,
  POST_COLLECTE_LIVRAISON,
} from './types';

const initialState = {
  collecte: null,
  items: null,
  livraisons: null,
}

function addLivraison(state, livraison) {
  const livraisons = state.livraisons;
  if (livraisons.length === 0) {
    return { ...state, livraisons: [livraison]}
  }
  return { ...state, livraisons: [...livraisons, livraison]}
}

export default function (state = initialState, action) {
	switch(action.type) {
		case GET_COLLECTE:
      return { ...state, collecte: action.payload };
    case GET_COLLECTE_CSV:
      return { ...state };
    case GET_COLLECTE_ITEM:
      return { ...state, items: action.payload };
    case GET_COLLECTE_LIVRAISONS:
      return { ...state, livraisons: action.payload };
    case POST_COLLECTE_LIVRAISON:
      return addLivraison(state, action.payload);
		default:
			return { ...state };
	}
}
