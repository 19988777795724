import { GET_LIVRAISONS, PATCH_LIVRAISON, DELETE_LIVRAISON } from "./types";

const initialState = {
  livraisons: null,
};

function updateLivraison(state, livraison) {
  const livraisons = state.livraisons;
  if (livraisons === null) {
    return { ...state };
  } else {
    const new_livraisons = livraisons.map((x) =>
      x.id === livraison.id ? livraison : x
    );
    return { ...state, livraisons: new_livraisons };
  }
}

function deleteLivraison(state, livraison) {
  const livraisons = state.livraisons;
  if (livraisons === null) {
    return { ...state };
  } else {
    const new_livraisons = livraisons.filter((x) => x.id !== livraison.id);
    return { ...state, livraisons: new_livraisons };
  }
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_LIVRAISONS:
      return { ...state, livraisons: action.payload };
    case PATCH_LIVRAISON:
      return updateLivraison(state, action.payload);
    case DELETE_LIVRAISON:
      return deleteLivraison(state, action.payload);
    default:
      return { ...state };
  }
}
