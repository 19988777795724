import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Input } from 'antd';

const { TextArea } = Input;

export default class NoteForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    handleNoteText: PropTypes.func.isRequired,
    note_text: PropTypes.oneOfType([
      PropTypes.oneOf([null]).isRequired,
      PropTypes.string.isRequired,
    ]),
  }

  handleSubmit = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    this.props.handleSubmit();
  }

  render() {
    const { note_text } = this.props;
    return (
      <form onSubmit={e => this.handleSubmit(e)}>
        <div className="div-input">
          <label>Texte</label>
          <TextArea
            placeholder=""
            name="note_text"
            value={note_text}
            rows={4}
            onChange={(e) => this.props.handleNoteText(e.target.value)}
          />
        </div>
        <div className="submit">
          <button type="submit">Enregistrer</button>
        </div>
      </form>
    );
  }
}
