import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Input, Checkbox } from 'antd';

import ImageUploader from '../elements/imageUploader';

export default class Produit extends Component {
  static propTypes = {
    produit: PropTypes.object.isRequired,
    patchProduit: PropTypes.func.isRequired,
  }

  static defaultProps = {
    produit: {},
    patchProduit: () => {},
  }

  constructor(props) {
    super(props);
    this.state = {
      photo: null,
      nom: null,
      prix: null,
      conditionnement: null,
      disponible: null,
      ref: null,
      is_heavy: false,
    }
  }

  componentDidMount() {
    if (this.props.produit !== null) {
      this.setState({
        photo: this.props.produit.photo,
        nom: this.props.produit.nom,
        prix: this.props.produit.prix,
        conditionnement: this.props.produit.conditionnement,
        disponible: this.props.produit.disponible,
        ref: this.props.produit.ref,
        is_heavy: this.props.produit.is_heavy,
      });
    }
  }

  changePhoto = (photo) => {
    this.setState({ photo });
  }

  changeNom = (nom) => {
    this.setState({ nom });
  }

  changePrix = (prix) => {
    this.setState({ prix });
  }

  changeConditionnement = (conditionnement) => {
    this.setState({ conditionnement });
  }

  changeDisponible = (disponible) => {
    this.setState({ disponible });
  }

  changeRef = (ref) => {
    this.setState({ ref });
  }

  changeIsHeavy = (is_heavy) => {
    this.setState({ is_heavy });
  }

  handleSubmit = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    this.props.patchProduit({ ...this.state });
  }

  render() {

    return (
      <form className="produit-view-block" onSubmit={e => this.handleSubmit(e)}>
        <div className="produit-view-item">
          <div className="div-input long">
            <label>Photo de couverture</label>
            <ImageUploader
              changePhoto={(photo) => this.changePhoto(photo)}
              image={this.state.photo}
            />
          </div>
        </div>
        <div className="produit-view-item">
          <div className="div-input">
            <label>Nom du produit</label>
            <Input
              placeholder=""
              name="nom"
              value={this.state.nom}
              onChange={(e) => this.changeNom(e.target.value)}
            />
          </div>
          <div className="div-input">
            <label>Prix donateur</label>
            <Input
              placeholder=""
              name="prix"
              type="number"
              value={this.state.prix}
              onChange={(e) => this.changePrix(e.target.value)}
            />
          </div>
          <div className="div-input">
            <label>Conditionnement</label>
            <Input
              placeholder=""
              name="conditionnement"
              value={this.state.conditionnement}
              onChange={(e) => this.changeConditionnement(e.target.value)}
            />
          </div>
          <div className="div-input">
            <label>Reference du produit</label>
            <Input
              placeholder=""
              name="ref"
              value={this.state.ref}
              onChange={(e) => this.changeRef(e.target.value)}
            />
          </div>
          <div className="div-input">
            <label>Disponibilité du produit en ligne</label>
            <Checkbox
              style={{ width: 300 }}
              checked={this.state.disponible}
              onChange={(e) => this.changeDisponible(e.target.checked)}
            >
              {this.state.disponible ? "Disponible":"Non disponible"}
            </Checkbox>
          </div>
          <div className="div-input">
            <label>Est ce que c'est un produit lourd ?</label>
            <Checkbox
              style={{ width: 300 }}
              checked={this.state.is_heavy}
              onChange={(e) => this.changeIsHeavy(e.target.checked)}
            >
              {this.state.is_heavy ? "Oui":"Non"}
            </Checkbox>
          </div>
          <div className="submit">
            <button disabled={this.state.nom === null || this.state.photo === null || this.state.ref === null || this.state.conditionnement === null || this.state.prix === null} type="submit">
              Enregistrer
            </button>
          </div>
        </div>
      </form>
    );
  }
}
