import React, { Component } from "react";
import PropTypes from "prop-types";

import { Input, Select } from "antd";

const { Option } = Select;

export default class AddProduit extends Component {
  static propTypes = {
    categories: PropTypes.array.isRequired,
    postProduit: PropTypes.func.isRequired,
  };

  static defaultProps = {
    categories: [],
    postProduit: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      categorie: null,
      sous_categorie: null,
      nom: null,
      conditionnement: null,
      prix: null,
      pourcentage_tva: 20,
    };
  }

  changeNom = (nom) => {
    this.setState({ nom });
  };

  changeCategory = (categorie) => {
    this.setState({ categorie });
  };

  changePrix = (prix) => {
    this.setState({ prix });
  };

  changePourcentageTVA = (pourcentage_tva) => {
    this.setState({ pourcentage_tva });
  };

  changeConditionnement = (conditionnement) => {
    this.setState({ conditionnement });
  };

  changeSousCat = (sous_categorie) => {
    this.setState({ sous_categorie });
  };

  handleSubmit = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    this.props.postProduit({ ...this.state });
  };

  render() {
    const { categories } = this.props;
    const {
      categorie,
      nom,
      sous_categorie,
      conditionnement,
      prix,
      pourcentage_tva,
    } = this.state;

    return (
      <form className="form" onSubmit={(e) => this.handleSubmit(e)}>
        <div className="div-input long">
          <label>Nom du produit</label>
          <Input
            placeholder=""
            name="nom"
            value={nom}
            onChange={(e) => this.changeNom(e.target.value)}
          />
        </div>
        <div className="div-input long">
          <label>Prix donateur</label>
          <Input
            placeholder=""
            name="prix"
            type="number"
            value={prix}
            onChange={(e) => this.changePrix(e.target.value)}
          />
        </div>
        <div className="div-input long">
          <label>Pourcentage TVA</label>
          <Input
            placeholder=""
            name="prix"
            type="number"
            min="0"
            max="100"
            step="0.1"
            value={pourcentage_tva}
            onChange={(e) => this.changePourcentageTVA(e.target.value)}
          />
        </div>
        <div className="div-input long">
          <label>Conditionnement</label>
          <Input
            placeholder=""
            name="conditionnement"
            value={conditionnement}
            onChange={(e) => this.changeConditionnement(e.target.value)}
          />
        </div>
        <div className="div-input long">
          <label>Catégorie du produit</label>
          <Select
            onChange={this.changeCategory}
            placeholder="Choisir une catégorie"
          >
            {categories.map((x) => (
              <Option key={x.id} value={x.id}>
                {x.nom}
              </Option>
            ))}
          </Select>
        </div>
        {categorie !== null && (
          <div className="div-input long">
            <label>Sous Catégorie du produit</label>
            <Select
              onChange={this.changeSousCat}
              placeholder="Choisir une sous catégorie"
            >
              {categories
                .find((y) => y.id === categorie)
                .sous_categorie.map((x) => (
                  <Option key={x.id} value={x.id}>
                    {x.nom}
                  </Option>
                ))}
            </Select>
          </div>
        )}
        <div className="submit">
          <button
            disabled={
              nom === null ||
              categorie === null ||
              sous_categorie === null ||
              prix === null ||
              conditionnement === null ||
              pourcentage_tva === null
            }
            type="submit"
          >
            Enregistrer
          </button>
        </div>
      </form>
    );
  }
}
