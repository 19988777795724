import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getAssociations,
  getOrders,
  getCollectes,
  getCash,
  refresh,
} from "./actions";

import "./dashboard.scss";
import "../../components/dashboard/style.scss";

import Dashboard from "../../components/dashboard/dashboard";
import Stats from "../../components/dashboard/stats";

class DashboardContainer extends Component {
  static propTypes = {
    associations: PropTypes.oneOfType([
      PropTypes.oneOf([null]).isRequired,
      PropTypes.array.isRequired,
    ]),
    collectes: PropTypes.oneOfType([
      PropTypes.oneOf([null]).isRequired,
      PropTypes.array.isRequired,
    ]),
    orders: PropTypes.oneOfType([
      PropTypes.oneOf([null]).isRequired,
      PropTypes.array.isRequired,
    ]),
  };

  static defaultProps = {
    associations: null,
    collectes: null,
    orders: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      transformedOrders: [],
    };
  }

  componentDidMount() {
    this.props.getAssociations();
    this.props.getCollectes();
    this.props.getOrders();
    this.props.getCash();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.orders === null && this.props.orders !== null) {
      const temp_transformedOrders = this.props.orders
        .sort(
          (a, b) =>
            new Date(a.order_date).getTime() - new Date(b.order_date).getTime()
        )
        .reduce((acc, val) => {
          const temp_date = new Date(val.order_date);
          const date = `${temp_date.getDate()}/${
            temp_date.getMonth() + 1
          }/${temp_date.getFullYear()}`;
          if (date in acc) {
            acc[date] += val.price;
          } else {
            acc[date] = val.price;
          }
          return acc;
        }, {});
      const temp_today = new Date();
      const today = `${temp_today.getDate()}/${
        temp_today.getMonth() + 1
      }/${temp_today.getFullYear()}`;
      const transformedOrdersArray = [];
      if (temp_transformedOrders !== {}) {
        Object.keys(temp_transformedOrders).map((x) => {
          return transformedOrdersArray.push({
            key: x,
            value: parseInt(temp_transformedOrders[x]),
          });
        });
      }

      if (!(today in temp_transformedOrders)) {
        transformedOrdersArray.push({ key: today, value: 0 });
      }

      this.setState({ transformedOrders: transformedOrdersArray });
    }
  }

  componentWillUnmount() {
    this.props.refresh();
  }

  render() {
    const { associations, collectes, orders, cash } = this.props;
    const { transformedOrders } = this.state;
    const date = new Date();
    const last_month = new Date(date.setMonth(date.getMonth() - 1));

    if (associations === null || collectes === null || orders === null) {
      return null;
    }

    return (
      <div className="dashboard-container">
        <h1 style={{ fontSize: "2em", color: "#2b8bbc", textAlign: "center" }}>
          Metrics
        </h1>
        <Dashboard
          stats={{
            associations: associations.length,
            collectes: collectes.filter((x) => x.validated === true).length,
            orders: orders.length,
          }}
          collectes={collectes
            .filter((x) => x.validated === true)
            .filter((x) => new Date(x.date_fin) >= last_month)}
          orders={orders.filter((x) => new Date(x.order_date) >= last_month)}
          cash={cash}
        />
        {transformedOrders && <Stats data={transformedOrders} />}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    associations: state.dashboard.associations,
    collectes: state.dashboard.collectes,
    orders: state.dashboard.orders,
    cash: state.dashboard.cash,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getAssociations,
      getOrders,
      getCollectes,
      getCash,
      refresh,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);
