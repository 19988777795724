import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import {
  getAssociation,
  getAssociationCollectes,
  patchAssociation,
  getNotes,
  postNote,
  patchNote,
  deleteNote,
  refresh,
} from './actions';

import "./association_view.scss";
import "../../components/association_view/style.scss";

import AssociationInformation from '../../components/association_view/information';
import AssociationVerification from '../../components/association_view/verification';
import AssociationCollectes from '../../components/association_view/association_collectes';
import AssociationNotes from '../../components/association_view/notes';
import AssociationMenu from '../../components/association_view/menu';

class AssociationViewContainer extends Component {
  static propTypes = {
    association: PropTypes.oneOfType([
      PropTypes.oneOf([null]).isRequired,
      PropTypes.object.isRequired,
    ]),
  }

  static defaultProps = {
    association: null,
  }

  constructor(props) {
    super(props);
    this.state = {
      menu: 0,
    }
  }

  componentDidMount() {
    this.props.getAssociation(this.props.association_id);
    this.props.getAssociationCollectes(this.props.association_id);
    this.props.getNotes(this.props.association_id);
  }

  componentWillUnmount() {
    this.props.refresh();
  }

  changeMenu = (menu) => {
    this.setState({ menu });
  }

  renderCase = (menu, association, collectes, notes) => {
    switch (menu) {
      case 0:
        return <AssociationInformation association={association} />
      case 1:
        return <AssociationVerification documents={association.documents} />
      case 2:
        return <AssociationCollectes collectes={collectes} />
      case 3:
        return <AssociationNotes
        notes={notes}
        postNote={(data) => this.props.postNote(this.props.association_id, data)}
        patchNote={(note_id, data) => this.props.patchNote(this.props.association_id, note_id, data)}
        deleteNote={(note_id) => this.props.deleteNote(this.props.association_id, note_id)}
        />
      default:
        return <AssociationInformation />
    }
  }

  render() {
    const { association, collectes, notes } = this.props;

    if (association === null || notes === null || collectes === null) {
      return null;
    }

    return (
      <div className="association-view-container">
        {!association.verified &&
          <div className="cta-validate" onClick={() => this.props.patchAssociation(this.props.association_id, { verified: true })}>
            Vérifier l'association
          </div>
        }
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div className="logo" style={{ margin: '0px 10px', backgroundImage: `url('${association.logo}')` }}></div>
          <h1 style={{ fontSize: '2em', margin: '0px 10px', color: '#2b8bbc', textAlign: 'center' }}>{association.nom}</h1>
        </div>
        <AssociationMenu
          menu={this.state.menu}
          changeMenu={(data) => this.changeMenu(data)}
        />
        {this.renderCase(this.state.menu, association, collectes, notes)}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    association: state.association_view.association,
    collectes: state.association_view.collectes,
    notes: state.association_view.notes,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getAssociation,
    getAssociationCollectes,
    patchAssociation,
    getNotes,
    postNote,
    patchNote,
    deleteNote,
    refresh,
  },dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AssociationViewContainer);
