import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import CommandeTracking from '../../components/livraison_view/commandeTracking';
import TrackingItemsDrag from '../../components/livraison_view/trackingItemsDrag';

export default class Tracking extends Component {
  static propTypes = {
    commande: PropTypes.object.isRequired,
    bon: PropTypes.oneOfType([
      PropTypes.oneOf([null]).isRequired,
      PropTypes.object.isRequired,
    ]),
    livraison_item: PropTypes.oneOfType([
      PropTypes.oneOf([null]).isRequired,
      PropTypes.object.isRequired,
    ]),
    getLivraisonItem: PropTypes.func.isRequired,
    getLivraisonBon: PropTypes.func.isRequired,
    patchLivraisonBon: PropTypes.func.isRequired,
    patchLivraisonItem: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      rows: null,
    }
  }

  componentDidMount() {
    this.setState({ rows: this.props.commande.bonDeCommandes.sort((a,b) => a.id - b.id) });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.bon === null && this.props.bon !== null) {
      const newRows = [...this.state.rows, this.props.bon];
      this.setState({ rows: newRows });
      this.props.getLivraisonBon();
    }

    if (prevProps.livraison_item === null && this.props.livraison_item !== null) {
      const newRowOrder = this.props.livraison_item.bonDeCommandes;
      const result = [];
      this.state.rows.forEach(x => {
        result.push(newRowOrder.find(y => y.id === x.id));
      });

      const newState = {
        ...this.state,
        rows: result,
      }

      this.setState(newState);
      this.props.getLivraisonItem();
    }

    if (prevProps.commande !== this.props.commande && prevProps.commande !== null && this.props.commande !== null) {
      this.setState({ rows: this.props.commande.bonDeCommandes.sort((a,b) => a.id - b.id) });
    }
  }

  handleDragItems = (destination, source,  temp_draggableId) => {
    const draggableId = temp_draggableId.split('-')[1];
    if (destination.droppableId === source.droppableId) {
      const row = this.state.rows.find(x => x.id === parseInt(source.droppableId));
      const commandeItem = row.commandeItems.find(y => y.id === parseInt(draggableId));
      const newCommandeItems = row.commandeItems;
      newCommandeItems.splice(parseInt(source.index), 1);
      newCommandeItems.splice(parseInt(destination.index), 0, commandeItem);

      const newRow = {
        ...row,
        commandeItems: newCommandeItems
      }

      const newRows = this.state.rows.map(x => {
        if (x.id === parseInt(source.droppableId)) {
          return newRow;
        }
        return x;
      });
      const newState = {
        ...this.state,
        rows: newRows,
      }

      this.setState(newState);
    } else {
      const initialRow = this.state.rows.find(x => x.id === parseInt(source.droppableId));
      const row = this.state.rows.find(x => x.id === parseInt(destination.droppableId));
      const commandeItem = initialRow.commandeItems.find(y => y.id === parseInt(draggableId));
      const initialCommandeItems = initialRow.commandeItems;
      const newCommandeItems = row.commandeItems;
      initialCommandeItems.splice(parseInt(source.index), 1);
      newCommandeItems.splice(parseInt(destination.index), 0, commandeItem);

      const initRow = {
        ...initialRow,
        commandeItems: initialCommandeItems
      }

      const newRow = {
        ...row,
        commandeItems: newCommandeItems
      }

      const newRows = this.state.rows.map(x => {
        if (x.id === parseInt(source.droppableId)) {
          return initRow;
        } else if (x.id === parseInt(destination.droppableId)) {
          return newRow;
        }
        return x;
      });
      const newState = {
        ...this.state,
        rows: newRows,
      }

      this.props.patchLivraisonItem(parseInt(draggableId), `?bon_id=${parseInt(destination.droppableId)}`);

      this.setState(newState);
    }
  }

  onDragEnd = (result) => {
    const { destination, source, draggableId, type } = result;

    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    if (type === 'bon') {
      const newRowOrder = this.state.rows;
      const new_draggableId = draggableId.split('-')[1];
      const row = this.state.rows.find(y => y.id === parseInt(new_draggableId));
      newRowOrder.splice(parseInt(source.index), 1);
      newRowOrder.splice(parseInt(destination.index), 0, row);

      const newState = {
        ...this.state,
        rows: newRowOrder,
      }

      this.setState(newState);
    } else {
      this.handleDragItems(destination, source, draggableId);
    }
  }

  patchLivraisonItem = (id, column_id, data) => {
    const column = this.state.rows.find(x => x.id === column_id);
    const item = column.commandeItems.find(x => x.id === id);
    const new_rows = this.state.rows.map(x => {
      if (x.default) {
        if (x.commandeItems.find(y => parseInt(y.id) === parseInt(id)) !== undefined) {
          x.commandeItems.map(y => {
            if (y.id === id) {
              y.quantity = y.quantity + 1;
            }
            return y;
          });
        } else if(x.commandeItems.find(y => parseInt(y.article.id) === parseInt(item.article.id)) !== undefined) {
          x.commandeItems.map(y => {
            if (y.article.id === item.article.id) {
              y.quantity = y.quantity + 1;
            }
            return y;
          });
        } else {
          const new_item = { ...item, quantity: 1};
          x.commandeItems.push(new_item);
        }
      } else {
        const new_items = x.commandeItems.map(y => {
          if (y.id === id && column_id === x.id) {
            y.quantity = y.quantity - 1;
          }
          return y;
        }).filter(y => {
          if (y.id === id && y.quantity === 0) {
            return false;
          }
          return true;
        });
        x.commandeItems = new_items;
      }
      return x;
    });

    this.props.patchLivraisonItem(id, data);

    const newState = {
      ...this.state,
      rows: new_rows,
    }
    this.setState(newState);
  }

  render() {
    const { rows } = this.state;
    const { commande } = this.props;
    if (rows === null) {
      return null;
    }

    return (
      <DragDropContext
        onDragEnd={this.onDragEnd}
      >
        <Droppable droppableId="all-columns" direction="vertical" type="bon">
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {rows.map((x, index) => {
              return (
                <div key={x.id}>
                  <Draggable draggableId={`column-${x.id}`} index={index}>
                    {(provided_alt) => (
                      <div
                        {...provided_alt.draggableProps}
                        ref={provided_alt.innerRef}
                      >
                        <CommandeTracking
                          key={`${x.id}-1`}
                          data={x}
                          patchLivraisonBon={(data) => this.props.patchLivraisonBon(commande.id, x.id, data)}
                        />
                        <div
                          className="tracking-items-container"
                        >
                          <h2 className="titre" {...provided_alt.dragHandleProps}>
                            Tracking Number: {x.numero_de_tracking}
                          </h2>
                          <TrackingItemsDrag
                            key={`${x.id}-2`}
                            items={x}
                            handleQuantityItem={(id, data) => this.patchLivraisonItem(id, x.id, `?quantity=${data}`)}
                          />
                        </div>
                      </div>
                  )}
                </Draggable>
              </div>
            )})}
            {provided.placeholder}
          </div>
        )}
        </Droppable>
      </DragDropContext>
    );
  }
}
