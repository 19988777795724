import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import { Table, Popconfirm, Checkbox } from 'antd';

import moment from 'moment';
import 'moment/locale/fr'

moment.locale('fr');

const renderNameCollecte = (value, row, index) => {
  return <Link to={`/equipe/livraison/${row.id}`}>{value.collecte}</Link>
}

const renderNameAssociation = (value, row, index) => {
  return <div>{value.association}</div>;
}

const renderCheck = (value, row, index) => {
  if (row.validated === true) {
    return <div style={{ textAlign: 'center' }}><i className="fad fa-check"></i></div>;
  } else {
    return <div style={{ textAlign: 'center' }}><i className="fad fa-times"></i></div>;
  }
}

const renderCheckRecu = (value, row, index) => {
  if (row.delivered === true) {
    return <div style={{ textAlign: 'center' }}><i className="fad fa-check"></i></div>;
  } else {
    return <div style={{ textAlign: 'center' }}><i className="fad fa-times"></i></div>;
  }
}

const renderDate = (value, row, index) => {
  const date = new Date(value);

  return <div>{date.getDate()}/{date.getMonth() + 1}/{date.getFullYear()}</div>
}



export default class LivraisonTable extends Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
    updateLivraison: PropTypes.func.isRequired,
    deleteLivraison: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      current: 1,
    }
  }

  onChange = (e, id) => {
    if (e.target.checked) {
      this.props.updateLivraison(id, { validated: true, delivered_on_our_side: true });
    }
  }

  handlePagination = (pagination) => {
    this.setState({ current: pagination.page });
  }

  render() {
    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        render: id => <Link to={`/equipe/livraison/${id}`}>{id}</Link>,
        sorter: (a, b) => b.id - a.id,
      },
      {
        title: 'Collecte',
        dataIndex: 'nom_collecte_asso',
        render: renderNameCollecte,
      },
      {
        title: 'Association',
        dataIndex: 'nom_collecte_asso',
        render: renderNameAssociation,
      },
      {
        title: 'Valider',
        dataIndex: 'validated',
        render: renderCheck,
      },
      {
        title: 'Reçu',
        dataIndex: 'delivered',
        render: renderCheckRecu,
      },
      {
        title: 'Crée le',
        dataIndex: 'created_at',
        render: renderDate,
      },
      {
        title: 'Livrée ?',
        dataIndex: '',
        render: (value, row, index) => {
          return (
            <Checkbox
              defaultChecked={row.delivered_on_our_side === true ? true:false}
              disabled={row.delivered_on_our_side}
              onChange={(e) => this.onChange(e, row.id)}
            >
              Livrée
            </Checkbox>
          );
        }
      },
      {
        title: 'Effacer',
        dataIndex: '',
        render: (value, row, index) => {
          return (
            <Popconfirm
              title="T'es sure de vouloir effacer ?"
              onConfirm={() => this.props.deleteLivraison(row.id)}
            >
              <div style={{ textAlign: 'center', cursor: 'pointer' }}>
                <a href={"#"} style={{ color: 'red' }}>
                  <i className="fad fa-times"></i>
                </a>
              </div>
            </Popconfirm>
          );
        }
      }
    ];


    const { data } = this.props;
    const { current } = this.state;
    return (
      <Table
        columns={columns}
        dataSource={data}
        pagination={{
          position: 'bottomCenter',
          defaultPageSize: 20,
          hideOnSinglePage: true,
          current: current
        }}
        rowKey={(record) => record.id}
        onChange={(pagination) => this.handlePagination(pagination)}
        bordered
      />
    );
  }
}
