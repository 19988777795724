import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Input } from "antd";

import {
  getLivraisons,
  getLivraisonByCommande,
  updateLivraison,
  deleteLivraison,
  refresh,
} from "./actions";

import "./livraisons.scss";
import "../../components/livraisons/style.scss";

import LivraisonTable from "../../components/livraisons/table";

const { Search } = Input;

class LivraisonsContainer extends Component {
  static propTypes = {
    livraisons: PropTypes.oneOfType([
      PropTypes.oneOf([null]).isRequired,
      PropTypes.array.isRequired,
    ]),
  };

  static defaultProps = {
    livraisons: null,
  };

  componentDidMount() {
    this.props.getLivraisons();
  }

  componentWillUnmount() {
    this.props.refresh();
  }

  handleLivraisonByCommande = (value) => {
    if (value !== "") {
      this.props.getLivraisonByCommande(value);
    }
  };

  renderSearchForm = () => {
    return (
      <div className="search-container">
        <Search
          placeholder="Rechercher un produit"
          onSearch={(value) => this.handleLivraisonByCommande(value)}
          className="search"
        />
      </div>
    );
  };

  render() {
    const { livraisons } = this.props;

    if (livraisons === null) {
      return null;
    }

    return (
      <div className="livraisons-container">
        <h1 style={{ fontSize: "2em", color: "#2b8bbc", textAlign: "center" }}>
          Livraisons{" "}
          <span
            onClick={() => this.props.getLivraisons()}
            style={{
              fontSize: "1em",
              cursor: "pointer",
              textDecoration: "underline",
            }}
          >
            <em>(tous)</em>
          </span>
        </h1>
        {this.renderSearchForm()}
        <LivraisonTable
          data={livraisons}
          updateLivraison={(id, data) => this.props.updateLivraison(id, data)}
          deleteLivraison={(id) => this.props.deleteLivraison(id)}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    livraisons: state.livraisons.livraisons,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getLivraisons,
      getLivraisonByCommande,
      updateLivraison,
      deleteLivraison,
      refresh,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LivraisonsContainer);
