import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Input } from 'antd';

export default class CommandeTracking extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    patchLivraisonBon: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      tracking: null,
      numero_de_commande: null,
      numero_de_tracking: null,
    }
  }

  componentDidMount() {
    if (this.props.data !== null) {
      this.setState({
        tracking: this.props.data.tracking,
        numero_de_commande: this.props.data.numero_de_commande,
        numero_de_tracking: this.props.data.numero_de_tracking,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data && prevProps.data !== null) {
      this.setState({
        tracking: this.props.data.tracking,
        numero_de_commande: this.props.data.numero_de_commande,
        numero_de_tracking: this.props.data.numero_de_tracking,
      });
    }
  }

  changeTracking = (tracking) => {
    if (tracking === '') {
      this.setState({ tracking: null });
    } else {
      this.setState({ tracking });
    }
  }

  changeNumeroDeCommande = (numero_de_commande) => {
    this.setState({ numero_de_commande });
  }

  changeNumeroDeTracking = (numero_de_tracking) => {
    this.setState({ numero_de_tracking });
  }

  handleSubmit = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    this.props.patchLivraisonBon({ ...this.state });
  }

  render() {
    const { tracking, numero_de_commande, numero_de_tracking } = this.state;

    return (
      <form className="form" onSubmit={e => this.handleSubmit(e)}>
        <div className="multiple-input">
          <div className="div-input">
            <label>Numero de commande</label>
            <Input
              placeholder=""
              name="numero_de_commande"
              value={numero_de_commande}
              onChange={(e) => this.changeNumeroDeCommande(e.target.value)}
            />
          </div>
          <div className="div-input">
            <label>Numero de tracking</label>
            <Input
              placeholder=""
              name="numero_de_tracking"
              value={numero_de_tracking}
              onChange={(e) => this.changeNumeroDeTracking(e.target.value)}
            />
          </div>
        </div>
        <div className="multiple-input">
          <div className="div-input">
            <label>Tracking URL</label>
            <Input
              placeholder=""
              name="tracking"
              value={tracking}
              onChange={(e) => this.changeTracking(e.target.value)}
            />
          </div>
          {tracking !== null && <div><a href={tracking} target="_blank" rel="noopener noreferrer">Lien</a></div>}
          <div className="submit tracking-cta">
            <button disabled={tracking === null && numero_de_commande === null && numero_de_tracking === null} type="submit">
              Enregistrer
            </button>
          </div>
        </div>
      </form>
    );
  }
}
