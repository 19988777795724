import {
	AUTH_ERROR,
	AUTH_VERIFICATION,
	FETCH_USER,
} from './types';

const initialState = {
  verify: null,
  user: null,
  error: null,
}

export default function (state = initialState, action) {
	switch(action.type) {
		case AUTH_ERROR:
			return { ...state, verify: action.verify };
		case AUTH_VERIFICATION:
			return { ...state, verify: action.verify };
		case FETCH_USER:
			return { ...state, user: action.payload };
		default:
			return { ...state };
	}

}
