import axios from 'axios';

import {
  GET_SUGGESTIONS,
  PATCH_SUGGESTIONS,
} from './types';

import API_ROUTES from '../../utils/api_routes';

const SUGGESTIONS_URL = API_ROUTES.suggestions;

export function getSuggestions() {
  return function(dispatch) {
    axios.get(`${SUGGESTIONS_URL}`, {
      headers: { Authorization: `JWT ${localStorage.getItem('wisdana-equipe-token')}` }
    })
    .then(response => {
      dispatch({ type: GET_SUGGESTIONS, payload: response.data });
    })
    .catch(error => {
      console.log(error.response);
      // console.log(error.response.data.error);
    })
  }
}

export function patchSuggestion(id) {
  return function(dispatch) {
    axios.patch(`${SUGGESTIONS_URL}${id}`, { available: true },  {
      headers: { Authorization: `JWT ${localStorage.getItem('wisdana-equipe-token')}` }
    })
    .then(response => {
      dispatch({ type: PATCH_SUGGESTIONS, payload: response.data });
    })
    .catch(error => {
      console.log(error.response);
    });
  }
}

export function refresh() {
  return function(dispatch) {
    dispatch({ type: GET_SUGGESTIONS, payload: null });
  }
}
