import axios from 'axios';

import {
  GET_ASSOCIATIONS,
} from './types';

import API_ROUTES from '../../utils/api_routes';

const ASSOCIATIONS_URL = API_ROUTES.associations;

export function getAssociationByName(name) {
  return function (dispatch) {
    dispatch({ type: GET_ASSOCIATIONS, payload: null });
    axios.get(`${ASSOCIATIONS_URL}?name=${name}`, {
      headers: { Authorization: `JWT ${localStorage.getItem('wisdana-equipe-token')}` }
    })
    .then(response => {
      dispatch({ type: GET_ASSOCIATIONS, payload: response.data });
    })
    .catch(error => {
      console.log(error.response);
    });
  }
}

export function getAssociations() {
  return function(dispatch) {
    dispatch({ type: GET_ASSOCIATIONS, payload: null });
    axios.get(`${ASSOCIATIONS_URL}`, {
      headers: { Authorization: `JWT ${localStorage.getItem('wisdana-equipe-token')}` }
    })
    .then(response => {
      dispatch({ type: GET_ASSOCIATIONS, payload: response.data });
    })
    .catch(error => {
      console.log(error.response);
      // console.log(error.response.data.error);
    })
  }
}

export function refresh() {
  return function(dispatch) {
    dispatch({ type: GET_ASSOCIATIONS, payload: null });
  }
}
