import React, { Component } from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import jwt from "jsonwebtoken";

import Header from "../../components/layout/header";
import Subheader from "../../components/layout/subheader";

import ProduitsContainer from "../produits/produitsContainer";
import ProduitViewContainer from "../produit_view/produit_viewContainer";

import LivraisonsContainer from "../livraisons/livraisonsContainer";
import LivraisonViewContainer from "../livraison_view/livraison_viewContainer";

import AssociationsContainer from "../associations/associationsContainer";
import AssociationsViewContainer from "../association_view/association_viewContainer";

import DashboardContainer from "../dashboard/dashboardContainer";

import SuggestionsContainer from "../suggestions/suggestionsContainer";

import CollectesContainer from "../collectes/collectesContainer";
import CollecteViewContainer from "../collecte_view/collecteViewContainer";

import MaintenanceContainer from "../maintenance";

import { refreshToken, verifyToken, getUser, refresh } from "./actions";

import "../../components/layout/style.scss";
import "../../components/elements/style.scss";

class LayoutContainer extends Component {
  static propTypes = {
    verify: PropTypes.oneOfType([
      PropTypes.bool.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
    error: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
    authenticated: PropTypes.oneOfType([
      PropTypes.bool.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
    user: PropTypes.oneOfType([
      PropTypes.object.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
  };

  componentDidMount() {
    this.props.verifyToken();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.verify === null && this.props.verify === true) {
      if (
        localStorage.getItem("wisdana-equipe-token") &&
        jwt.decode(localStorage.getItem("wisdana-equipe-token"))
      ) {
        const expiry = new Date(
          jwt.decode(localStorage.getItem("wisdana-equipe-token")).exp * 1000
        );
        const now = new Date();
        if (now.getDate() - expiry.getDate() === 1) {
          this.props.refreshToken();
        }
      }
    }
    return true;
  }

  componentWillUnmount() {
    this.props.refresh();
  }

  render() {
    const { verify, authenticated, user, location } = this.props;

    if (location.pathname === "/equipe") {
      return <Redirect push={false} to="/equipe/dashboard" />;
    }

    if (verify === false) {
      return (
        <Route
          render={({ staticContext }) => {
            // eslint-disable-next-line
            if (staticContext) staticContext.status = 403;
            return <Redirect to={"/equipe/login"} />;
          }}
        />
      );
    }

    if (verify && authenticated && user === null) {
      this.props.getUser();
    }

    if (authenticated && user !== null) {
      return (
        <Header user={user} location={this.props.location.pathname}>
          <Subheader user={user} location={this.props.location}>
            <Route path="/equipe/dashboard" exact={true}>
              <DashboardContainer />
            </Route>
            <Route path="/equipe/associations" exact={true}>
              <AssociationsContainer />
            </Route>
            <Route
              path="/equipe/association/:id"
              render={({ match }) => (
                <AssociationsViewContainer
                  association_id={parseInt(match.params.id)}
                />
              )}
            />
            <Route path="/equipe/produits" exact={true}>
              <ProduitsContainer />
            </Route>
            <Route
              path="/equipe/produit/:id"
              render={({ match }) => (
                <ProduitViewContainer produit_id={parseInt(match.params.id)} />
              )}
            />
            <Route path="/equipe/livraisons" exact={true}>
              <LivraisonsContainer />
            </Route>
            <Route
              path="/equipe/livraison/:id"
              render={({ match }) => (
                <LivraisonViewContainer
                  livraison_id={parseInt(match.params.id)}
                />
              )}
            />
            <Route path="/equipe/suggestions" exact={true}>
              <SuggestionsContainer />
            </Route>
            <Route path="/equipe/collectes" exact={true}>
              <CollectesContainer />
            </Route>
            <Route
              path="/equipe/collecte/:id"
              render={({ match }) => (
                <CollecteViewContainer
                  collecte_id={parseInt(match.params.id)}
                />
              )}
            />
            <Route path="/equipe/applications/maintenance" exact={true}>
              <MaintenanceContainer />
            </Route>
          </Subheader>
        </Header>
      );
    }

    return null;
  }
}

function mapStateToProps(state) {
  return {
    verify: state.layout.verify,
    error: state.layout.error,
    authenticated: state.auth.authenticated,
    user: state.layout.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      refreshToken,
      verifyToken,
      getUser,
      refresh,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutContainer);
