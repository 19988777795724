import {
  GET_COLLECTES,
} from './types';

const initialState = {
  collectes: null,
}

export default function (state = initialState, action) {
	switch(action.type) {
		case GET_COLLECTES:
      return { ...state, collectes: action.payload };
		default:
			return { ...state };
	}
}
