import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import { Table, Checkbox } from 'antd';

const renderName = (value, row, index) => {
  return <div style={{ textAlign: 'center' }}>{value}</div>
}

const renderNameAssociation = (value, row, index) => {
  return <div>{value.nom}</div>;
}

export default class SuggeestionsTable extends Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
    patchSuggestion: PropTypes.func.isRequired,
  }

  static defaultProps = {
    data: [],
    patchSuggestion: () => {},
  }

  onChange = (e, id) => {
    if (e.target.checked) {
      this.props.patchSuggestion(id);
    }
  }

  render() {
    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        render: id => <Link to={`/equipe/livraison/${id}`}>{id}</Link>,
        sorter: (a, b) => b.id - a.id,
      },
      {
        title: 'Nom',
        dataIndex: 'nom',
        render: renderName,
      },
      {
        title: 'Association',
        dataIndex: 'association',
        render: renderNameAssociation,
      },
      {
        title: 'Ajouté ?',
        dataIndex: '',
        render: (value, row, index) => {
          return (
            <Checkbox
              defaultChecked={row.available}
              disabled={row.available}
              onChange={(e) => this.onChange(e, row.id)}
            >
              Ajouté
            </Checkbox>
          );
        }
      }
    ];


    const { data } = this.props;
    return (
      <Table
        columns={columns}
        dataSource={data}
        rowKey={(record) => record.id}
        pagination={{ position: 'bottomCenter', defaultPageSize: 20 }}
        bordered
      />
    );
  }
}
