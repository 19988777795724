import axios from 'axios';

import {
	GET_ORDERS,
  GET_ASSOCIATIONS,
  GET_COLLECTES,
	GET_CASH,
} from './types';

import API_ROUTES from '../../utils/api_routes';

const ASSOCIATIONS_URL = API_ROUTES.associations;
const ORDERS_URL = API_ROUTES.orders;
const COLLECTES_URL = API_ROUTES.collectes;
const CASH_URL = API_ROUTES.cash;

export function getAssociations() {
  return function(dispatch) {
    axios.get(`${ASSOCIATIONS_URL}`, {
      headers: { Authorization: `JWT ${localStorage.getItem('wisdana-equipe-token')}` }
    })
    .then(response => {
      dispatch({ type: GET_ASSOCIATIONS, payload: response.data });
    })
    .catch(error => {
      console.log(error.response);
    })
  }
}

export function getCollectes() {
  return function(dispatch) {
    axios.get(`${COLLECTES_URL}`, {
      headers: { Authorization: `JWT ${localStorage.getItem('wisdana-equipe-token')}` }
    })
    .then(response => {
      dispatch({ type: GET_COLLECTES, payload: response.data });
    })
    .catch(error => {
      console.log(error.response);
    })
  }
}

export function getOrders() {
  return function(dispatch) {
    axios.get(`${ORDERS_URL}`, {
      headers: { Authorization: `JWT ${localStorage.getItem('wisdana-equipe-token')}` }
    })
    .then(response => {
      dispatch({ type: GET_ORDERS, payload: response.data });
    })
    .catch(error => {
      console.log(error.response);
    })
  }
}

export function getCash() {
	return function (dispatch) {
		axios.get(`${CASH_URL}`, {
      headers: { Authorization: `JWT ${localStorage.getItem('wisdana-equipe-token')}` }
    })
    .then(response => {
      dispatch({ type: GET_CASH, payload: response.data });
    })
    .catch(error => {
      console.log(error.response);
    })
	}
}

export function refresh() {
  return function(dispatch) {
    dispatch({ type: GET_ASSOCIATIONS, payload: null });
    dispatch({ type: GET_COLLECTES, payload: null });
    dispatch({ type: GET_ORDERS, payload: null });
		dispatch({ type: GET_CASH, payload: null });
  }
}
