import axios from 'axios';

import {
	AUTH_USER,
	AUTH_ERROR,
	UNAUTH_USER,
} from './types';

import API_ROUTES from '../../utils/api_routes';

const LOGIN_URL = API_ROUTES.login;

export function authUser({ username, password }) {
	return function(dispatch) {
		// Submit email and password
		const email = username.toLowerCase().trim();
		axios.post(`${LOGIN_URL}`, { username: email, password })
			.then(response => {
				// If request is good
				// - Save JWT token
				localStorage.setItem('wisdana-equipe-token', response.data.token);
				// - Update state => authd
				dispatch({ type: AUTH_USER, authenticated: true });
			})
			.catch((error) => {
				// If request is bad
				// - Show error	to user
				dispatch(authError(error.response.data.error));
			});
	}

}

export function authError(error) {
	return {
		type: AUTH_ERROR,
		error: error
	};
}

export function signoutUser() {
	localStorage.removeItem('wisdana-equipe-token');

	return function(dispatch) {
		dispatch({ type: UNAUTH_USER });
	}
}
