import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import { message } from 'antd';

import {
  getProduit,
  patchProduit,
  getProduitVendeur,
  patchProduitVendeur,
  getProduitPriceCheck,
  postProduitPriceCheck,
  patchProduitPriceCheck,
  deleteProduitPriceCheck,
  refresh,
} from './actions';

import Produit from '../../components/produit_view/produit';
import ProduitVendeur from '../../components/produit_view/produitVendeur';
import ProduitPriceCheck from '../../components/produit_view/produitPriceCheck';

import "./produit_view.scss";
import "../../components/produit_view/style.scss";

class ProduitViewContainer extends Component {
  static propTypes = {
    produit: PropTypes.oneOfType([
      PropTypes.oneOf([null]).isRequired,
      PropTypes.object.isRequired,
    ]),
  }

  static defaultProps = {
    produit: null,
  }

  componentDidMount() {
    this.props.getProduit(this.props.produit_id);
    this.props.getProduitVendeur(this.props.produit_id);
    this.props.getProduitPriceCheck(this.props.produit_id);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.produit !== this.props.produit && prevProps.produit !== null) {
      message.success({
        content: 'Vos changements ont bien été effectués',
        duration: 1,
      });
    }
  }

  componentWillUnmount() {
    this.props.refresh();
  }

  renderVendeur = (data, produit_id) => {
    if (data === null) {
      return null;
    }
    return data.map(x => {
      return <ProduitVendeur
        key={x.id}
        vendeur={x}
        patchProduitVendeur={(data) => this.props.patchProduitVendeur(produit_id, data)}
      />
    })
  }

  renderPriceCheck = (data, produit_id) => {
    if (data === null) {
      return null;
    }
    return data.map(x => {
      return <ProduitPriceCheck
        key={x.id}
        price_check={x}
        patchProduitPriceCheck={(data) => this.props.patchProduitPriceCheck(produit_id, data)}
        deleteProduitPriceCheck={(data) => this.props.deleteProduitPriceCheck(produit_id, data)}
      />
    })
  }

  render() {
    const { produit, vendeur, price_check, produit_id } = this.props;

    if (produit === null) {
      return null;
    }

    return (
      <div className="produit-view-container">
        {produit &&
          <div className="cadre-produit">
            <h1 className="produit-view-titre">{this.props.produit.ref}</h1>
            <Produit
              produit={produit}
              patchProduit={(data) => this.props.patchProduit(produit_id, data)}
            />
            <h3>Vendeurs</h3>
            {this.renderVendeur(vendeur, produit_id)}
            <h3>Price Check <span style={{ cursor: 'pointer' }} onClick={() => this.props.postProduitPriceCheck(produit_id)}><i className="fal fa-plus"></i></span></h3>
            {this.renderPriceCheck(price_check, produit_id)}
          </div>
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    produit: state.produit_view.produit,
    vendeur: state.produit_view.vendeur,
    price_check: state.produit_view.price_check,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getProduit,
    patchProduit,
    getProduitVendeur,
    patchProduitVendeur,
    getProduitPriceCheck,
    postProduitPriceCheck,
    patchProduitPriceCheck,
    deleteProduitPriceCheck,
    refresh,
  },dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ProduitViewContainer);
