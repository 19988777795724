import {
  GET_ASSOCIATION,
  GET_ASSOCIATION_COLLECTES,
  PATCH_ASSOCIATION,
  GET_NOTES,
  POST_NOTE,
  PATCH_NOTE,
  DELETE_NOTE,
} from './types';

const initialState = {
  association: null,
  collectes: null,
  notes: null,
}

function addNote(state, note) {
  const notes = state.notes;
  if (notes === null) {
    return { ...state, notes: [note]};
  } else {
    return { ...state, notes: [note, ...notes]}
  }
}

function updateNote(state, note) {
  const notes = state.notes;
  if (notes === null) {
    return { ...state, notes: null };
  } else {
    const new_notes = notes.map(x => {
      if (x.id === note.id) {
        return note;
      } else {
        return x;
      }
    }).sort((a,b) => b.id - a.id);
    return { ...state, notes: new_notes };
  }
}

function deleteNote(state, note_id) {
  const notes = state.notes;
  if (notes === null) {
    return { ...state, notes: null };
  } else {
    const new_notes = notes.filter(x => x.id !== note_id);
    return { ...state, notes: new_notes };
  }
}

export default function (state = initialState, action) {
	switch(action.type) {
		case GET_ASSOCIATION:
      return { ...state, association: action.payload };
    case PATCH_ASSOCIATION:
      return { ...state, association: action.payload };
    case GET_ASSOCIATION_COLLECTES:
      return { ...state, collectes: action.payload };
    case GET_NOTES:
      return { ...state, notes: action.payload };
    case POST_NOTE:
      return addNote(state, action.payload);
    case PATCH_NOTE:
      return updateNote(state, action.payload);
    case DELETE_NOTE:
      return deleteNote(state, action.payload);
		default:
			return { ...state };
	}
}
