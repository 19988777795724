import React, { Component } from "react";
import PropTypes from "prop-types";

export default class Dashboard extends Component {
  static propTypes = {
    stats: PropTypes.object.isRequired,
  };

  static defaultProps = {
    stats: {},
  };

  renderTopStat = (stats) => {
    return (
      <div className="dashboard-block" key={0}>
        <div className="dashboard-item">
          <h3>Commandes</h3>
          <div className="number">{stats.orders}</div>
        </div>
        <div className="dashboard-item">
          <h3>Collectes</h3>
          <div className="number">{stats.collectes}</div>
        </div>
        <div className="dashboard-item">
          <h3>Associations</h3>
          <div className="number">{stats.associations}</div>
        </div>
      </div>
    );
  };

  renderOMTM = (collectes, orders) => {
    const prices = [0, 0, 0, 0, 0];
    const new_orders = orders.reduce((acc, val) => {
      if (acc[val.collecte]) {
        acc[val.collecte] += val.price;
      } else {
        acc[val.collecte] = val.price;
      }
      return acc;
    }, {});
    collectes.forEach((item, i) => {
      if (new_orders[item.id]) {
        if (parseInt(new_orders[item.id]) > 1200) {
          prices[0] += 1;
        } else if (parseInt(new_orders[item.id]) > 600) {
          prices[1] += 1;
        } else if (parseInt(new_orders[item.id]) > 300) {
          prices[2] += 1;
        } else if (parseInt(new_orders[item.id]) > 100) {
          prices[3] += 1;
        } else {
          prices[4] += 1;
        }
      } else {
        prices[4] += 1;
      }
    });

    return (
      <div className="dashboard-block" key={1}>
        {prices.map((x, index) => {
          const categorie = index + 1;
          return (
            <div className="dashboard-item" key={categorie}>
              <h3>Categorie {categorie}</h3>
              <div className="number">{x}</div>
            </div>
          );
        })}
      </div>
    );
  };

  renderCashInCashOut = (cash) => {
    if (!cash) return <></>;
    return (
      <div className="dashboard-block" key={2}>
        <div className="dashboard-item">
          <h3>Revenue</h3>
          <div className="number">{cash.cash_in.toFixed(2)}€</div>
        </div>
        <div className="dashboard-item">
          <h3>Dépense</h3>
          <div className="number">{cash.depense.toFixed(2)}€</div>
        </div>
      </div>
    );
  };

  render() {
    const { stats, collectes, orders, cash } = this.props;

    return [
      this.renderTopStat(stats),
      this.renderCashInCashOut(cash),
      this.renderOMTM(collectes, orders),
    ];
  }
}
