import {
	GET_CATEGORIES,
	GET_PRODUITS,
	POST_PRODUIT,
} from './types';

const initialState = {
  produits: null,
  categories: null,
	nouveau_produit: null,
}

export default function (state = initialState, action) {
	switch(action.type) {
		case GET_CATEGORIES:
      return { ...state, categories: action.payload };
		case GET_PRODUITS:
			return { ...state, produits: action.payload };
		case POST_PRODUIT:
			return { ...state, nouveau_produit: action.payload };
		default:
			return { ...state };
	}
}
