import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import { trackWindowScroll } from 'react-lazy-load-image-component';

import {
  createDelivery,
  getCollecte,
  getCollecteCsv,
  getCollecteItems,
  getCollecteLivraisons,
  refresh,
} from './actions';

import "./collecte_view.scss";
import "../../components/collecte_view/style.scss";

import CollecteViewProduct from '../../components/collecte_view/collecte_view_product';
import CollecteViewLivraison from '../../components/collecte_view/collecte_view_livraison';

class CollecteViewContainer extends Component {
  static propTypes = {
    collecte: PropTypes.oneOfType([
      PropTypes.oneOf([null]).isRequired,
      PropTypes.object.isRequired,
    ]),
  }

  constructor(props) {
    super(props);
    this.state = {
      menu: 0,
    }
  }

  componentDidMount() {
    this.props.getCollecte(this.props.collecte_id);
    this.props.getCollecteItems(this.props.collecte_id);
    this.props.getCollecteLivraisons(this.props.collecte_id);
  }

  componentWillUnmount() {
    this.props.refresh();
  }

  handleMenu = (menu) => {
    this.setState({ menu });
  }

  handleCreateDelivery = () => {
    this.props.createDelivery(this.props.collecte_id);
  }

  renderSort = (items, collecte, livraisons, menu, scrollPosition) => {
    switch(menu) {
      case 0:
        return <CollecteViewProduct collecte={collecte} items={items} scrollPosition={scrollPosition} />
      case 1:
        return <CollecteViewLivraison collecte={collecte} livraisons={livraisons} createDelivery={() => this.handleCreateDelivery()}/>
      default:
        return <CollecteViewProduct collecte={collecte} items={items} scrollPosition={scrollPosition} />
    }
  }

  renderMenu = (selected) => {
    const arr = [{'name': 'Produits', 'key': 0 }, { 'name': 'Livraison', 'key': 1 }];
    return arr.map(x => {
      return (
        <p
          key={x.key}
          className={x.key === selected ? "collecte-filtre active":"collecte-filtre"}
          onClick={() => this.handleMenu(x.key)}
        >
          {x.name}
        </p>
      );
    });
  }

  render() {
    const { menu } = this.state;
    const { collecte, items, livraisons, collecte_id, scrollPosition } = this.props;

    if (collecte === null || items === null || livraisons === null) {
      return null;
    }

    return (
      <div className="collecte-view-container">
        <div className="cta-validate" onClick={() => this.props.getCollecteCsv(collecte_id)}>
          Telecharger Facebook
        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <h1 style={{ fontSize: '2em', margin: '0px 10px', color: '#2b8bbc', textAlign: 'center' }}>{collecte.nom}</h1>
        </div>
        <div className="collecte-filtre-block">
          {this.renderMenu(menu)}
        </div>
        {this.renderSort(items, collecte, livraisons, menu, scrollPosition)}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    collecte: state.collecte_view.collecte,
    items: state.collecte_view.items,
    livraisons: state.collecte_view.livraisons,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    createDelivery,
    getCollecte,
    getCollecteCsv,
    getCollecteItems,
    getCollecteLivraisons,
    refresh,
  },dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(trackWindowScroll(CollecteViewContainer));
