import React, { useEffect } from "react";

// Styling
import { Breadcrumb, Col, Row, Space, Switch } from "antd";

// RedUX
import { getApplications, toggleMaintenance } from "./action";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";

const MaintenanceContainer = () => {
  const { applications } = useSelector((state) => state.applications);
  const dispatch = useDispatch();

  const actions = bindActionCreators(
    { getApplications, toggleMaintenance },
    dispatch
  );

  useEffect(() => {
    actions.getApplications();
  }, []);

  const setWebsiteMaintenanceMode = (website, maintenance) => {
    const url = website.replace(/(^\w+:|^)\/\//, "");
    actions.toggleMaintenance(url, maintenance);
  };

  return (
    <div style={{ padding: 32 }}>
      <Row style={{ marginBottom: 32 }}>
        <Col>
          <Breadcrumb>
            <Breadcrumb.Item>
              <i className={`fad fa-home`} style={{ color: "#2b8bbc" }}></i>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <i className={`fad fa-wrench`} style={{ color: "#2b8bbc" }}></i>
              <span> &nbsp; Paramètres</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Maintenance</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      <Space size="large" direction="vertical" style={{ marginLeft: 8 * 4 }}>
        <h1>Gestion de la maintenance</h1>

        <p>
          Activer et désactiver la mode de maintenance en cliquant sur le bouton
          de l'application concernée.
        </p>
        {applications.map((app) => (
          <Row key={`app-id-${app.id}`}>
            <Switch
              checked={app.maintenance}
              onChange={() =>
                setWebsiteMaintenanceMode(app.url, !app.maintenance)
              }
            />
            &nbsp;{app?.name || app.url}
            {app.maintenance && app.maintenance_edited_at && (
              <>
                {" "}
                &mdash; Active depuis le{" "}
                {new Date(app.maintenance_edited_at).toLocaleTimeString(
                  "fr-fr",
                  {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  }
                )}
              </>
            )}
          </Row>
        ))}

        <small>
          La date et le compte à l'origine de la dernière
          activation/désactivation du mode maintenance sont conservés.
        </small>
      </Space>
    </div>
  );
};

export default MaintenanceContainer;
