import {
	GET_PRODUIT,
  PATCH_PRODUIT,
	GET_PRODUIT_VENDEUR,
	PATCH_PRODUIT_VENDEUR,
	GET_PRODUIT_PRICE_CHECK,
	POST_PRODUIT_PRICE_CHECK,
	PATCH_PRODUIT_PRICE_CHECK,
	DELETE_PRODUIT_PRICE_CHECK,
} from './types';

const initialState = {
  produit: null,
	vendeur: null,
	price_check: null,
}

function updateVendeur(state, vendeur) {
	const vendeurs = state.vendeur;

	if (vendeurs.length === 0) {
		return { ...state, vendeur }
	}
	const new_vendeurs = vendeurs.map(x => {
		if (x.id === vendeur.id) {
			return vendeur;
		} else {
			return x;
		}
	});
	return { ...state, vendeur: new_vendeurs };
}

function postPriceCheck(state, price_check) {
	const priceChecks = state.price_check;
	if (priceChecks.length === 0) {
		return { ...state, price_check: [price_check]}
	}
	const new_priceChecks = [...priceChecks, price_check];
	return { ...state, price_check: new_priceChecks.sort((a,b) => a.id - b.id) }
}

function updatePriceCheck(state, price_check) {
	const priceChecks = state.price_check;

	if (priceChecks.length === 0) {
		return { ...state, price_check }
	}
	const new_priceChecks = priceChecks.map(x => {
		if (x.id === price_check.id) {
			return price_check;
		} else {
			return x;
		}
	});
	return { ...state, price_check: new_priceChecks };
}

function deletePriceCheck(state, price_check) {
	const priceChecks = state.price_check;
	if (priceChecks.length === 0) {
		return { ...state, price_check: []}
	}
	const new_priceChecks = priceChecks.filter(x => x.id !== parseInt(price_check.id));
	return { ...state, price_check: new_priceChecks};
}

export default function (state = initialState, action) {
	switch(action.type) {
		case GET_PRODUIT:
			return { ...state, produit: action.payload };
    case PATCH_PRODUIT:
      return { ...state, produit: action.payload };
		case GET_PRODUIT_VENDEUR:
			return { ...state, vendeur: action.payload };
		case PATCH_PRODUIT_VENDEUR:
			return updateVendeur(state, action.payload);
		case GET_PRODUIT_PRICE_CHECK:
			return { ...state, price_check: action.payload !== null ? action.payload.sort((a,b) => a.id - b.id):action.payload };
		case POST_PRODUIT_PRICE_CHECK:
			return postPriceCheck(state, action.payload);
		case PATCH_PRODUIT_PRICE_CHECK:
			return updatePriceCheck(state, action.payload);
		case DELETE_PRODUIT_PRICE_CHECK:
			return deletePriceCheck(state, action.payload);
		default:
			return { ...state };
	}
}
