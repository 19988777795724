import axios from 'axios';

import {
  GET_LIVRAISON,
  GET_LIVRAISON_ADRESSE,
  PATCH_LIVRAISON,
  PATCH_ORDER_LINE_ITEM,
  GET_LIVRAISON_COMMANDE,
  PATCH_LIVRAISON_COMMANDE,
  PATCH_LIVRAISON_BON,
  POST_LIVRAISON_BON,
  GET_LIVRAISON_BON,
  PATCH_LIVRAISON_ITEM,
  PATCH_LIVRAISON_ITEM_UPDATING,
} from './types';

import API_ROUTES from '../../utils/api_routes';

const LIVRAISONS_URL = API_ROUTES.livraisons;
const LIVRAISON_ITEM_URL = API_ROUTES.livraison_item;

export function getLivraison(livraison_id) {
  return function(dispatch) {
    axios.get(`${LIVRAISONS_URL}${livraison_id}/`, {
      headers: { Authorization: `JWT ${localStorage.getItem('wisdana-equipe-token')}` }
    })
    .then(response => {
      dispatch({ type: GET_LIVRAISON, payload: response.data });
    })
    .catch(error => {
      console.log(error.response);
    })
  }
}

export function getLivraisonAdresse(livraison_id) {
  return function(dispatch) {
    axios.get(`${LIVRAISONS_URL}${livraison_id}/adresse/`, {
      headers: { Authorization: `JWT ${localStorage.getItem('wisdana-equipe-token')}` }
    })
    .then(response => {
      dispatch({ type: GET_LIVRAISON_ADRESSE, payload: response.data });
    })
    .catch(error => {
      console.log(error.response);
    })
  }
}

export function getLivraisonCommande(livraison_id) {
  return function(dispatch) {
    axios.get(`${LIVRAISONS_URL}${livraison_id}/commande/`, {
      headers: { Authorization: `JWT ${localStorage.getItem('wisdana-equipe-token')}` }
    })
    .then(response => {
      dispatch({ type: GET_LIVRAISON_COMMANDE, payload: response.data });
    })
    .catch(error => {
      console.log(error.response);
    });
  }
}

export function patchLivraison(data, livraison_id) {
  return function(dispatch) {
    axios.patch(`${LIVRAISONS_URL}${livraison_id}/`, data, {
      headers: { Authorization: `JWT ${localStorage.getItem('wisdana-equipe-token')}` }
    })
    .then(response => {
      dispatch({ type: PATCH_LIVRAISON, payload: response.data });
    })
    .catch(error => {
      console.log(error.response);
    });
  }
}

export function patchOrderLineItem(livraison_id, article_id, item_id, data) {
  return function(dispatch) {
    axios.patch(`${LIVRAISONS_URL}${livraison_id}/article/${article_id}/livraison-commande-item/${item_id}/`, data, {
      headers: { Authorization: `JWT ${localStorage.getItem('wisdana-equipe-token')}` }
    })
    .then(response => {
      dispatch({ type: PATCH_ORDER_LINE_ITEM, payload: response.data });
    })
    .catch(error => {
      console.log(error.response);
      // console.log(error.response.data.error);
    })
  }
}

export function patchLivraisonCommande(livraison_id, commande_id, data) {
  return function(dispatch) {
    axios.patch(`${LIVRAISONS_URL}${livraison_id}/commande/${commande_id}/`, data, {
      headers: { Authorization: `JWT ${localStorage.getItem('wisdana-equipe-token')}` }
    })
    .then(response => {
      dispatch({ type: PATCH_LIVRAISON_COMMANDE, payload: response.data });
    })
    .catch(error => {
      console.log(error.response);;
    })
  }
}

export function patchLivraisonBon(livraison_id, commande_id, bon_id, data) {
  return function(dispatch) {
    axios.patch(`${LIVRAISONS_URL}${livraison_id}/commande/${commande_id}/bon/${bon_id}/`, data, {
      headers: { Authorization: `JWT ${localStorage.getItem('wisdana-equipe-token')}` }
    })
    .then(response => {
      dispatch({ type: PATCH_LIVRAISON_BON, payload: response.data });
    })
    .catch(error => {
      console.log(error.response);;
    })
  }
}

export function patchQuantity(livraison_id, item_id, data) {
  return function(dispatch) {
    axios.patch(`${LIVRAISONS_URL}${livraison_id}/livraison-commande-item/${item_id}/`, data, {
      headers: { Authorization: `JWT ${localStorage.getItem('wisdana-equipe-token')}` }
    })
    .then(response => {
      dispatch({ type: PATCH_ORDER_LINE_ITEM, payload: response.data });
    })
    .catch(error => {
      console.log(error.response);;
    })
  }
}

export function postLivraisonBon(livraison_id, commande_id, data) {
  return function(dispatch) {
    axios.post(`${LIVRAISONS_URL}${livraison_id}/commande/${commande_id}/bon/`, data , {
      headers: { Authorization: `JWT ${localStorage.getItem('wisdana-equipe-token')}` }
    })
    .then(response => {
      dispatch({ type: POST_LIVRAISON_BON, payload: response.data });
    })
    .catch(error => {
      console.log(error.response);
    });
  }
}

export function patchLivraisonItem(item_id, bon_id) {
  return function(dispatch) {
    axios.patch(`${LIVRAISON_ITEM_URL}${item_id}/${bon_id}`, {}, {
      headers: { Authorization: `JWT ${localStorage.getItem('wisdana-equipe-token')}` }
    })
    .then(response => {
      dispatch({ type: PATCH_LIVRAISON_ITEM, payload: response.data });
    })
    .catch(error => {
      console.log(error.response);
    })
  }
}

export function getLivraisonItem() {
  return function(dispatch) {
    dispatch({ type: PATCH_LIVRAISON_ITEM, payload: null });
  }
}

export function getLivraisonBon() {
  return function (dispatch) {
    dispatch({ type: GET_LIVRAISON_BON, payload: null });
  }
}

export function patchLivraisonItemUpdating() {
  return function(dispatch) {
    dispatch({ type: PATCH_LIVRAISON_ITEM_UPDATING });
  }
}

export function refresh() {
  return function(dispatch) {
    dispatch({ type: GET_LIVRAISON, payload: null });
    dispatch({ type: GET_LIVRAISON_COMMANDE, payload: null });
    dispatch({ type: GET_LIVRAISON_BON, payload: null });
    dispatch({ type: PATCH_LIVRAISON_ITEM, payload: null });
    dispatch({ type: GET_LIVRAISON_ADRESSE, payload: null });
    dispatch({ type: PATCH_LIVRAISON_ITEM_UPDATING });
  }
}
