export const GET_PRODUIT = 'containers/produit_view/get_produit';
export const PATCH_PRODUIT = 'containers/produit_view/patch_produit';

export const GET_PRODUIT_VENDEUR = 'containers/produit_view/get_produit_vendeur';
export const PATCH_PRODUIT_VENDEUR = 'containers/produit_view/patch_produit_vendeur';

export const GET_PRODUIT_PRICE_CHECK = 'containers/produit_view/get_produit_price_check';
export const POST_PRODUIT_PRICE_CHECK = 'containers/produit_view/post_produit_price_check';
export const PATCH_PRODUIT_PRICE_CHECK = 'containers/produit_view/patch_produit_price_check';
export const DELETE_PRODUIT_PRICE_CHECK = 'containers/produit_view/delete_produit_price_check';
