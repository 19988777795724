import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import "./style.scss";

export default class Subheader extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
    location: PropTypes.object.isRequired,
    user: PropTypes.shape({
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
    }).isRequired,
  };

  renderMenu = (location) => {
    const array = [
      {
        key: "/equipe/dashboard",
        value: "Dashboard",
        icon: "fa-chart-line",
      },
      {
        key: "/equipe/associations",
        value: "Associations",
        icon: "fa-award",
      },
      {
        key: "/equipe/produits",
        value: "Nos produits",
        icon: "fa-gifts",
      },
      {
        key: "/equipe/livraisons",
        value: "Livraisons",
        icon: "fa-truck",
      },
      {
        key: "/equipe/suggestions",
        value: "Suggestions de produits",
        icon: "fa-bags-shopping",
      },
      {
        key: "/equipe/collectes",
        value: "Collectes",
        icon: "fa-abacus",
      },
      {
        key: "/equipe/applications/maintenance",
        value: "Maintenance",
        icon: "fa-wrench",
      },
    ];

    return array.map(({ key, icon, value }, i) => {
      return (
        <Link
          key={key + i}
          className={
            location === key
              ? "dashboard-panel-item active"
              : "dashboard-panel-item"
          }
          to={key}
        >
          <div className="dashboard-panel-item-icon-text">
            <i className={`fad ${icon}`} style={{ color: "#2b8bbc" }}></i>
            <p className="dashboard-panel-item-text">{value}</p>
          </div>
        </Link>
      );
    });
  };

  render() {
    const { children, location, user } = this.props;

    return (
      <div className="dashboard-header">
        <div className="dashboard-panel">
          <h2 className="dashboard-panel-title">
            {user.username === "quentin" ? (
              <i className="fad fa-3x fa-chess-king-alt"></i>
            ) : (
              <i className="fad fa-3x fa-paw-claws"></i>
            )}
          </h2>
          <div className="dashboard-panel-menu">
            {this.renderMenu(location.pathname)}
            <Link key={0} className="dashboard-panel-item" to="/equipe/logout">
              <div className="dashboard-panel-item-icon-text">
                <i className="fad fa-sign-out" style={{ color: "#2b8bbc" }}></i>
                <p className="dashboard-panel-item-text">Logout</p>
              </div>
            </Link>
          </div>
        </div>
        <div className="component-layout">{children}</div>
      </div>
    );
  }
}
