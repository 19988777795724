import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Input } from 'antd';

export default class CommandeDetails extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    patchLivraisonCommande: PropTypes.func.isRequired,
  }

  static defaultProps = {
    data: {},
    patchLivraisonCommande: () => {},
  }

  constructor(props) {
    super(props);
    this.state = {
      numero_de_commande: null,
      prix_paye: null,
    }
  }

  componentDidMount() {
    if (this.props.data !== null) {
      this.setState({
        numero_de_commande: this.props.data.numero_de_commande,
        prix_paye: this.props.data.prix_paye,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data && prevProps.data !== null && this.props.data !== null) {
      this.setState({
        numero_de_commande: this.props.data.numero_de_commande,
        prix_paye: this.props.data.prix_paye,
      });
    }
  }

  changeNumeroDeCommande = (numero_de_commande) => {
    this.setState({ numero_de_commande });
  }

  changePrixPaye = (prix_paye) => {
    this.setState({ prix_paye });
  } 

  handleSubmit = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    this.props.patchLivraisonCommande({ ...this.state });
  }

  render() {
    const { numero_de_commande, prix_paye } = this.state;

    return (
      <form className="form" onSubmit={e => this.handleSubmit(e)}>
        <div className="div-input long">
          <label>Numéro de commande</label>
          <Input
            placeholder=""
            name="numero_de_commande"
            value={numero_de_commande}
            onChange={(e) => this.changeNumeroDeCommande(e.target.value)}
          />
        </div>
        <div className="div-input long">
          <label>Prix payé</label>
          <Input
            placeholder=""
            name="prix_paye"
            type="number"
            value={prix_paye}
            onChange={(e) => this.changePrixPaye(e.target.value)}
          />
        </div>
        <div className="submit">
          <button disabled={prix_paye === null || numero_de_commande === null} type="submit">
            Enregistrer
          </button>
        </div>
      </form>
    );
  }
}
