import axios from 'axios';

import {
  GET_COLLECTE,
  GET_COLLECTE_CSV,
  GET_COLLECTE_ITEM,
  GET_COLLECTE_LIVRAISONS,
  POST_COLLECTE_LIVRAISON,
} from './types';

import API_ROUTES from '../../utils/api_routes';

import FileSaver from 'file-saver';

const COLLECTES_URL = API_ROUTES.collectes;

export function getCollecte(id) {
  return function(dispatch) {
    axios.get(`${COLLECTES_URL}${id}/`, {
      headers: { Authorization: `JWT ${localStorage.getItem('wisdana-equipe-token')}` }
    })
    .then(response => {
      dispatch({ type: GET_COLLECTE, payload: response.data });
    })
    .catch(error => {
      console.log(error.response);
    })
  }
}

export function getCollecteCsv(id) {
  return function(dispatch) {
    axios.get(`${COLLECTES_URL}${id}/csv/`, {
      headers: { Authorization: `JWT ${localStorage.getItem('wisdana-equipe-token')}` }
    })
    .then(response => {
      const blob = new Blob([response.data.file], {type: 'text/csv;charset=utf-8'});
			FileSaver.saveAs(blob, response.data.nom);
      dispatch({ type: GET_COLLECTE_CSV });
    })
    .catch(error => {
      console.log(error.response);
    })
  }
}

export function getCollecteItems(id) {
  return function(dispatch) {
    axios.get(`${COLLECTES_URL}${id}/order-item/`, {
      headers: { Authorization: `JWT ${localStorage.getItem('wisdana-equipe-token')}` }
    })
    .then(response => {
      dispatch({ type: GET_COLLECTE_ITEM, payload: response.data });
    })
    .catch(error => {
      console.log(error.response);
    })
  }
}

export function getCollecteLivraisons(id) {
  return function(dispatch) {
    axios.get(`${COLLECTES_URL}${id}/livraisons/`, {
      headers: { Authorization: `JWT ${localStorage.getItem('wisdana-equipe-token')}` }
    })
    .then(response => {
      dispatch({ type: GET_COLLECTE_LIVRAISONS, payload: response.data });
    })
    .catch(error => {
      console.log(error.response);
    })
  }
}

export function createDelivery(id) {
  return function(dispatch) {
    axios.post(`${COLLECTES_URL}${id}/livraisons/`, {}, {
      headers: { Authorization: `JWT ${localStorage.getItem('wisdana-equipe-token')}` }
    })
    .then(response => {
      dispatch({ type: POST_COLLECTE_LIVRAISON, payload: response.data });
    })
    .catch(error => {
      console.log(error.response);
    });
  }
}

export function refresh() {
  return function(dispatch) {
    dispatch({ type: GET_COLLECTE, payload: null });
    dispatch({ type: GET_COLLECTE_CSV });
    dispatch({ type: GET_COLLECTE_ITEM, payload: null });
    dispatch({ type: GET_COLLECTE_LIVRAISONS, payload: null });
  }
}
