import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import { message } from 'antd';

import {
  getLivraison,
  getLivraisonAdresse,
  patchLivraison,
  patchOrderLineItem,
  getLivraisonCommande,
  patchLivraisonCommande,
  patchLivraisonBon,
  patchQuantity,
  postLivraisonBon,
  getLivraisonBon,
  getLivraisonItem,
  patchLivraisonItem,
  patchLivraisonItemUpdating,
  refresh,
} from './actions';

import "./livraison_view.scss";
import "../../components/livraison_view/style.scss";

import LivraisonItems from '../../components/livraison_view/livraison_view.js';
import CommandeDetails from '../../components/livraison_view/commandeDetails';
import Tracking from '../../components/livraison_view/tracking';

let hide = null;

class LivraisonViewContainer extends Component {
  static propTypes = {
    livraison: PropTypes.oneOfType([
      PropTypes.oneOf([null]).isRequired,
      PropTypes.object.isRequired,
    ]),
    livraison_id: PropTypes.number.isRequired,
  }

  static defaultProps = {
    livraison: null,
    livraison_id: 0,
  }

  constructor(props) {
    super(props);
    this.state = {
      stores: null,
      menu: 0,
      livraison: null,
    }
  }

  componentDidMount() {
    this.props.getLivraison(this.props.livraison_id);
    this.props.getLivraisonCommande(this.props.livraison_id);
    this.props.getLivraisonAdresse(this.props.livraison_id);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.commandes === null && this.props.commandes !== null) {
      const temp = this.props.commandes;
      const stores = temp.map(x => {
        return { vendeur: x.vendeur, id: x.livraisonCommandeItems[0].bon_de_livraison }
      });
      this.setState({ menu: this.props.commandes[0].id, stores });
    }

    if (prevProps.livraison === null && this.props.livraison !== null) {
      this.setState({ livraison: this.props.livraison });
    }

    if (prevProps.livraison !== null && this.props.livraison !== null && prevProps.livraison !== this.props.livraison) {
      this.setState({ livraison: this.props.livraison });
    }

    if (prevProps.updating === false && this.props.updating === true) {
      hide();
      message.success('Changement effectué');
      this.props.patchLivraisonItemUpdating();
    }
  }

  componentWillUnmount() {
    this.props.refresh();
  }

  changeMenu = (menu) => {
    this.setState({ menu });
  }

  calculateDepense = (commandes) => {
    const depenses = commandes.reduce((acc, val) => {
      acc += parseFloat(val.prix_paye);
      return acc;
    }, 0.00);
    return `${depenses.toFixed(2)}`;
  }

  patchLivraisonItem = (item_id, bon_id) => {
    this.props.patchLivraisonItem(item_id, bon_id);
    hide = message.loading("Changement en cours", 0);
  }

  renderDateFin = (date_fin, terminer) => {
    if (terminer === true) {
      return "Terminée";
    }
    const now = new Date();
    const difference_in_time = new Date(date_fin).getTime() - now.getTime();
    const days = difference_in_time / (1000 * 3600 * 24);
    if (Math.round(days) > 0) {
        return `Se termine dans ${Math.ceil(days)} jours`;
    } else if (Math.round(days) === 0) {
      return "Se termine Aujourd'hui";
    }
    return "Terminée";
  }

  renderLivraisonItems = (livraison, commandes, stores) => {
    return commandes.map(x => {
      return <LivraisonItems
        key={x.id}
        store_name={x.vendeur}
        stores={stores}
        order_line_items={livraison.items}
        items={x.livraisonCommandeItems}
        patchOrderLineItem={(article_id, item_id, data) => this.props.patchOrderLineItem(this.props.livraison_id, article_id, item_id, data)}
        patchQuantity={(item_id, data) => this.props.patchQuantity(this.props.livraison_id, item_id, data)}
      />
    });
  }

  renderMenu = (commandes, menu) => {
    return commandes.map(x => {
      return (
        <div
          key={x.id}
          className={x.id === menu ? "livraison-view-menu-item active":"livraison-view-menu-item"}
          onClick={() => this.changeMenu(x.id)}
        >
          {x.vendeur}
        </div>
      )
    });
  }

  renderCorrectCommande = (livraison, menu, bon, livraison_item) => {
    const commande = livraison.commandes.find(x => x.id === menu);
    return (
      <div className="commande-block">
        <CommandeDetails
          data={commande}
          patchLivraisonCommande={(data) => this.props.patchLivraisonCommande(this.props.livraison_id, commande.id, data)}
        />
        <div className="commande-block-hr"></div>
        <div
          className="add-bon-de-livraison"
          onClick={() => this.props.postLivraisonBon(this.props.livraison_id, commande.id)}
        >
          Ajouter un tracking
        </div>
        <Tracking
          commande={commande}
          bon={bon}
          livraison_item={livraison_item}
          getLivraisonItem={() => this.props.getLivraisonItem()}
          getLivraisonBon={() => this.props.getLivraisonBon()}
          patchLivraisonBon={(b,c,d) => this.props.patchLivraisonBon(this.props.livraison_id,b,c,d)}
          patchLivraisonItem={(item_id, bon_id) => this.patchLivraisonItem(item_id, bon_id)}
        />
      </div>
    )
  }

  render() {
    const { livraison_id, commandes, bon, livraison_item, adresse } = this.props;
    const { menu, stores, livraison } = this.state;

    if (livraison === null) {
      return null;
    }

    return (
      <div className="livraison-view-container">
        {!livraison.validated &&
          <div className="livraison-item-validate" onClick={() => this.props.patchLivraison({ validated: true, delivered_on_our_side: false }, livraison_id)}>
            Valider la livraison
          </div>
        }
        <div className="livraison-depense">
          <h3 style={{ color: 'red', textAlign: 'center' }}>Dépense des clients: {livraison.depense.toFixed(2)}€</h3>
          {commandes !== null &&
            <h3 style={{ color: 'red', textAlign: 'center' }}>Nos dépenses: {this.calculateDepense(commandes)}€</h3>
          }
        </div>
        <h1 style={{ fontSize: '2em', color: '#2b8bbc', textAlign: 'center' }}>Livraison #{livraison.id}</h1>
        {adresse && <h3>{this.renderDateFin(adresse.date_fin, adresse.terminer)}</h3>}
        {adresse && <h2 style={{ color: '#2b8bbc' }}>{adresse.association.nom}</h2>}
        {adresse && <span>{adresse.first_last_name}</span>}
        {adresse && adresse.lieu_de_livraison.split(',').map((x, index) => {
          if (index === 3) return null;
          if (index === 2) return <p key={index}>{x}</p>;
          return <span key={index}>{x}</span>;
        })}
        <p>{adresse && adresse.informations_de_livraison}</p>
        <p>{adresse && adresse.telephone_de_livraison}</p>
        <p>{adresse && adresse.email}</p>
        {livraison.validated && commandes &&
          <div className="livraison-view-menu">
            {this.renderMenu(commandes, menu)}
          </div>
        }
        {livraison.validated && commandes && menu !== 0 &&
          <div className="livraison-view-commande-block">
            {this.renderCorrectCommande(livraison, menu, bon, livraison_item)}
          </div>
        }
        {!livraison.validated && commandes && stores &&
          this.renderLivraisonItems(livraison, commandes, stores)
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    livraison: state.livraison_view.livraison,
    commandes: state.livraison_view.commandes,
    bon: state.livraison_view.bon,
    livraison_item: state.livraison_view.livraison_item,
    adresse: state.livraison_view.adresse,
    updating: state.livraison_view.updating,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getLivraison,
    getLivraisonAdresse,
    patchLivraison,
    patchOrderLineItem,
    getLivraisonCommande,
    patchLivraisonCommande,
    patchLivraisonBon,
    patchQuantity,
    postLivraisonBon,
    getLivraisonBon,
    getLivraisonItem,
    patchLivraisonItem,
    patchLivraisonItemUpdating,
    refresh,
  },dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(LivraisonViewContainer);
