import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export default class CollecteViewLivraison extends Component {
  static propTypes = {
    livraisons: PropTypes.array.isRequired,
    collecte: PropTypes.object.isRequired,
  }



  renderLivraison = (livraison, collecte) => {
    return (
      <Link key={livraison.id} className="collecte-view-livraison" to={`/equipe/livraison/${livraison.id}`}>
        <div className="collecte-view-livraison-title">
          Livraison {livraison.id}
        </div>
        <img src={collecte.photo} alt="collecte" />
        <div className="collecte-view-livraison-body">
          <p>Dépense: {livraison.commandes.reduce((acc, val) => {acc += parseFloat(val.prix_paye); return acc;}, 0.00)}€</p>
          <p>Revenu: {livraison.depense}€</p>
          <p>Reçu et confirmer par les assos: {livraison.delivered ? <i className="fal fa-check" style={{ color: 'green' }}></i>:<i className="fal fa-times" style={{ color: 'red' }}></i>}</p>
          <p>Reçu par les assos: {livraison.delivered_on_our_side ? <i className="fal fa-check" style={{ color: 'green' }}></i>:<i className="fal fa-times" style={{ color: 'red' }}></i>}</p>
        </div>
      </Link>
    );
  }


  render() {
    const { livraisons, collecte } = this.props;
    return (
      <div>
        <div
          className="collecte-view-livraison-cta"
          onClick={() => this.props.createDelivery()}
        >
          Creer une livraison
        </div>
        <div className="collecte-view-livraison-container">
          {livraisons.map(x => {
            return this.renderLivraison(x, collecte);
          })}
        </div>
      </div>
    );
  }
}
