import React, { Component } from 'react';
import PropTypes from 'prop-types';

const menus = [
  {
    key: 2,
    value: 'Collectes',
  },
  {
    key: 0,
    value: 'Information',
  },
  {
    key: 1,
    value: 'Vérification',
  },{
    key: 3,
    value: 'Notes',
  }
];

export default class Menu extends Component {
  static propTypes = {
    menu: PropTypes.number.isRequired,
    changeMenu: PropTypes.func.isRequired,
  }

  static defaultProps = {
    menu: 0,
    changeMenu: () => {},
  }


  render() {
    const { menu } = this.props;

    return (
      <div className="association_view-menu-block">
        {menus.map(x => {
          return (
            <div
              key={x.key}
              className={menu === x.key ? "association_view-menu-item active":"association_view-menu-item"}
              onClick={() => this.props.changeMenu(x.key)}
            >
              {x.value}
            </div>
          );
        })}
      </div>
    );
  }
}
