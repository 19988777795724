import axios from 'axios';

import {
	AUTH_ERROR,
	AUTH_VERIFICATION,
  UNAUTH_USER,
	AUTH_USER,
	FETCH_USER,
} from './types';

import API_ROUTES from '../../utils/api_routes';

const REFRESH_URL = API_ROUTES.token_refresh;
const VERIFY_URL = API_ROUTES.token_verify;
const USER_URL = API_ROUTES.user;

export function refreshToken() {
	return function(dispatch) {
		axios.post(`${REFRESH_URL}`, { token: localStorage.getItem('wisdana-equipe-token') })
		.then(response => {
			localStorage.setItem('wisdana-equipe-token', response.data.token);

			dispatch({ type: AUTH_USER, authenticated: true, activation: true });
		})
		.catch(err => {
			dispatch({ type: UNAUTH_USER });
		});
	}
}

export function verifyToken() {
	return function(dispatch) {
		axios.post(`${VERIFY_URL}`, { token: localStorage.getItem('wisdana-equipe-token') })
		.then(response => {
			dispatch({ type: AUTH_VERIFICATION, verify: true, authed: true });
		})
		.catch(err => {
			dispatch({ type: AUTH_ERROR, verify: false });
		});
	}
}

export function refresh() {
	return function(dispatch) {
		dispatch({ type: AUTH_VERIFICATION, verify: null, authed: null });
		dispatch({ type: FETCH_USER, user: null });
	}
}

export function authError(error) {
	return {
		type: AUTH_ERROR,
		error: error
	};
}

export function getUser() {
	return function(dispatch) {
		axios.get(`${USER_URL}`, {
			headers: { Authorization: `JWT ${localStorage.getItem('wisdana-equipe-token')}` }
		})
		.then(response => {
			dispatch({ type: FETCH_USER, payload: response.data });
		})
		.catch(error => {
			console.log(error.response);
		})
	}
}
