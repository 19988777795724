import {
  GET_ASSOCIATIONS
} from './types';

const initialState = {
  associations: null,
}

export default function (state = initialState, action) {
	switch(action.type) {
		case GET_ASSOCIATIONS:
      return { ...state, associations: action.payload };
		default:
			return { ...state };
	}
}
