import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Table, Popconfirm, Modal, Input } from 'antd';

export default class LivraisonItems extends Component {
  static propTypes = {
    store_name: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    patchOrderLineItem: PropTypes.func.isRequired,
    patchQuantity: PropTypes.func.isRequired,
    order_line_items: PropTypes.array.isRequired,
  }

  state = {
    visible: false,
    item_id: null,
    max_quantity: null,
    quantity: 0,
    visible_arr: false,
    arr: null,
    initial_price: null,
  }

  changeQuantity = (visible, id, max_quantity) => {
    this.setState({
      item_id: id,
      visible,
      max_quantity,
      quantity: 0,
    });
  }

  handleQuantity = (quantity, max_quantity) => {
    if (quantity > max_quantity) {
      this.setState({ quantity: max_quantity });
    } else {
      this.setState({ quantity });
    }
  }

  handleSubmit = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    this.props.patchQuantity(this.state.item_id, { quantity: this.state.quantity, max_quantity: this.state.max_quantity });
    this.changeQuantity(false, null, null);
  }

  handlePriceShow = (arr, initial_price) => {
    this.setState({ visible_arr: true, arr, initial_price });
  }

  toggleOffPriceShow = () => {
    this.setState({ visible_arr: false, arr: null, initial_price: null });
  }

  renderExpandable = (record, stores) => {
    return record.article.price_check.map(x => {
      const vendeur_id = stores.find(y => y.vendeur.toLowerCase() === x.vendeur.toLowerCase());
      return (
        <div key={x.id} className="expanded-block">
          <div className="expanded-item">
            {x.vendeur}
          </div>
          <div className="expanded-item">
            {x.price}
          </div>
          <div className="expanded-item">
            <a href={x.site_url} target="_blank" rel="noopener noreferrer">Lien</a>
          </div>
          <div className="expanded-item cta" onClick={() => this.props.patchOrderLineItem(x.article, record.id, { livraison: vendeur_id.id })}>
            Choisir
          </div>
        </div>
      );
    });
  }

  render() {
    const { store_name, stores, items, order_line_items } = this.props;
    const { visible, max_quantity, quantity, visible_arr, arr, initial_price } = this.state;

    const columns = [
      {
        title: 'Ref',
        dataIndex: '',
        render: (value, row, index) => row.article.ref,
        width: 160,
      },
      {
        title: 'Nom',
        dataIndex: '',
        render: (value, row, index) => <a href={row.article.vendeur[0].url} target="_blank" rel="noopener noreferrer">{row.article.nom}</a>,
        width: 160,
      },
      {
        title: 'Prix',
        dataIndex: '',
        render: (value, row, index) => {
          const is_valid_arr = order_line_items.filter(x => x.article.id === row.article.id && parseFloat(x.price) < parseFloat(row.article.prix));
          if (is_valid_arr.length > 0) {
            return <span style={{ cursor: 'pointer' }} onClick={() => this.handlePriceShow(is_valid_arr, row.article.prix)}>{row.article.prix}<i className="fad fa-exclamation-triangle"></i></span>;
          } else {
            return <span>{row.article.prix}</span>;
          }
        },
        width: 80,
      },
      {
        title: 'Quantité',
        dataIndex: 'quantity',
        render: (value, row, index) => {
          const quantity = parseInt(value) * parseInt(row.article.conditionnement);
          return (
            <div
              onClick={() => this.changeQuantity(true, row.id, quantity)}
              style={{ textAlign: 'center', cursor: 'pointer', color: '#2b8bbc' }}
            >
              {quantity}
            </div>
          )
        },
        width: 80,
      },
      {
        title: 'Delete',
        dataIndex: '',
        key: 'x',
        render: (value, row, index) => {
          return (
            <Popconfirm title="T'es sure ?" onConfirm={() => this.props.patchOrderLineItem(row.article.id, row.id, { livraison: null })}>
              <div style={{ textAlign: 'center', color: 'red', cursor: 'pointer' }}>Delete</div>
            </Popconfirm>
          )
        },
        width: 160,
      }
    ];

    if (items === null) {
      return null;
    }

    return (
      <div className="livraison-item-container">
        <h2>Commande pour {store_name}</h2>
        <Table
          columns={columns}
          dataSource={items}
          pagination={{
            position: 'bottomCenter',
            defaultPageSize: 20,
            hideOnSinglePage: true,
          }}
          expandable={{
            expandedRowRender: (record) => this.renderExpandable(record, stores),
            rowExpandable: record => record.article.price_check.length > 0,
          }}
          rowKey={(record) => record.article.ref}
          bordered
        />
        <Modal
          title="Changement de quantité"
          visible={visible}
          footer={null}
          onCancel={() => this.changeQuantity(false, null, null)}
        >
          <h4 style={{ textAlign: 'center' }}>Quantité maximale: {max_quantity}</h4>
          <form onSubmit={e => this.handleSubmit(e)}>
            <div className="div-input">
              <label>Quantité</label>
              <Input
                placeholder=""
                name="quantity"
                type="number"
                value={quantity}
                onChange={(e) => this.handleQuantity(e.target.value, max_quantity)}
                max={max_quantity}
              />
            </div>
            <div className="submit">
              <button type="submit">Enregistrer</button>
            </div>
          </form>
        </Modal>
        <Modal
          title="Problème de prix client"
          visible={visible_arr}
          footer={null}
          onCancel={() => this.toggleOffPriceShow()}
        >
          <h4 style={{ textAlign: 'center' }}>Prix initial: {initial_price}</h4>
          <h4 style={{ textAlign: 'center' }}>Liste des clients</h4>
          {arr !== null && arr.map((x, index) => {
            return (
              <div key={x.id}>
                Prix payé par le client-{index + 1}: {x.price}
              </div>
            );
          })}
        </Modal>
      </div>
    );
  }
}
