import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import { trackWindowScroll } from 'react-lazy-load-image-component';

import {
  getCollectes,
  getCollecteByName,
  refresh,
} from './actions';

import "./collectes.scss";
import "../../components/collectes/style.scss";

import Collectes from '../../components/collectes/collectes';

class CollectesContainer extends Component {
  static propTypes = {
    collectes: PropTypes.oneOfType([
      PropTypes.oneOf([null]).isRequired,
      PropTypes.array.isRequired,
    ]),
  }

  constructor(props) {
    super(props);
    this.state = {
      selected: 0,
    }
  }

  componentDidMount() {
    this.props.getCollectes();
  }

  componentWillUnmount() {
    this.props.refresh();
  }

  handleSelected = (selected) => {
    this.setState({ selected });
  }

  handleCollecteByName = (data) => {
    this.setState({ selected: 0 });
    this.props.getCollecteByName(data);
  }

  sort = (arr, selected) => {
    switch(selected) {
      case 0:
        return arr;
      case 1:
        return arr.filter(x => x.terminer === false);
      case 2:
        return arr.filter(x => x.terminer === true);
      default:
        return arr;
    }
  }

  renderMenu = (selected) => {
    const arr = [{'name': 'Toutes', 'key': 0}, {'name': 'En cours', 'key': 1}, {'name': 'Terminée', 'key': 2}];
    return arr.map(x => {
      return (
        <p
          key={x.key}
          className={x.key === selected ? "collecte-filtre active":"collecte-filtre"}
          onClick={() => this.handleSelected(x.key)}
        >
          {x.name}
        </p>
      );
    });
  }

  render() {
    const { selected } = this.state;
    const { collectes, scrollPosition } = this.props;

    if (collectes === null) {
      return null;
    }

    return (
      <div className="collectes-container">
        <h1 style={{ fontSize: '2em', color: '#2b8bbc', textAlign: 'center' }}>Nos Collectes</h1>
        <div className="collecte-filtre-block">
          {this.renderMenu(selected)}
        </div>
        <Collectes
          collectes={this.sort(collectes, selected)}
          getCollecteByName={(data) => this.handleCollecteByName(data)}
          scrollPosition={scrollPosition}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    collectes: state.collectes.collectes,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getCollectes,
    getCollecteByName,
    refresh,
  },dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(trackWindowScroll(CollectesContainer));
