import axios from 'axios';

import {
  GET_LIVRAISONS,
  PATCH_LIVRAISON,
  DELETE_LIVRAISON,
} from './types';

import API_ROUTES from '../../utils/api_routes';

const LIVRAISONS_URL = API_ROUTES.livraisons;

export function getLivraisons() {
  return function(dispatch) {
    dispatch({ type: GET_LIVRAISONS, payload: null });
    axios.get(`${LIVRAISONS_URL}`, {
      headers: { Authorization: `JWT ${localStorage.getItem('wisdana-equipe-token')}` }
    })
    .then(response => {
      dispatch({ type: GET_LIVRAISONS, payload: response.data });
    })
    .catch(error => {
      console.log(error.response);
      // console.log(error.response.data.error);
    })
  }
}

export function getLivraisonByCommande(name) {
  return function(dispatch) {
    dispatch({ type: GET_LIVRAISONS, payload: null });
    axios.get(`${LIVRAISONS_URL}?name=${name}`, {
      headers: { Authorization: `JWT ${localStorage.getItem('wisdana-equipe-token')}` }
    })
    .then(response => {
      dispatch({ type: GET_LIVRAISONS, payload: response.data });
    })
    .catch(error => {
      console.log(error.response);
      // console.log(error.response.data.error);
    })
  }
}

export function updateLivraison(livraison_id, data) {
  return function(dispatch) {
    axios.patch(`${LIVRAISONS_URL}${livraison_id}/`, data, {
      headers: { Authorization: `JWT ${localStorage.getItem('wisdana-equipe-token')}` }
    })
    .then(response => {
      dispatch({ type: PATCH_LIVRAISON, payload: response.data });
    })
    .catch(error => {
      console.log(error.response);
    });
  }
}

export function deleteLivraison(livraison_id) {
  return function(dispatch) {
    axios.delete(`${LIVRAISONS_URL}${livraison_id}/`, {
      headers: { Authorization: `JWT ${localStorage.getItem('wisdana-equipe-token')}` }
    })
    .then(response => {
      dispatch({ type: DELETE_LIVRAISON, payload: response.data });
    })
    .catch(error => {
      console.log(error.response);
    });
  }
}

export function refresh() {
  return function(dispatch) {
    dispatch({ type: GET_LIVRAISONS, payload: null });
  }
}
