import React, { Component } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { authUser } from "./actions";

import logo from "../../resources/img/logo_white.svg";
import HeaderBG from "../../resources/img/header_bg.png";

import Login from "../../components/auth/login";

// import custom css
import "./auth.scss";
import "../../components/auth/style.scss";

import env from "../../config/env";
const { ASSOCIATION_URL } = env;

class AuthContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onLoginRedirectUrl: "/equipe/dashboard",
    };
  }

  static propTypes = {
    authenticated: PropTypes.oneOfType([
      PropTypes.bool.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
    error: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
  };

  static defaultProps = {
    authenticated: null,
    error: null,
  };

  authUser = (data) => {
    this.props.authUser(data);
  };

  render() {
    const { authenticated, error } = this.props;
    const { onLoginRedirectUrl } = this.state;

    if (authenticated) {
      return <Redirect push={true} to={onLoginRedirectUrl} />;
    }

    return (
      <div
        className="loginContainer"
        style={{ backgroundImage: `url(${HeaderBG})` }}
      >
        <div className="loginContainerBox">
          <img src={logo} className="logo" alt="logo" />
          <p className="association-question">
            Vous êtes une association ?{" "}
            <a href={`${ASSOCIATION_URL}/association/login`}>Cliquez ici</a>
          </p>
          <div className="loginSignupBox">
            <Login authUser={(data) => this.authUser(data)} error={error} />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    authenticated: state.auth.authenticated,
    error: state.auth.error,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      authUser,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthContainer);
